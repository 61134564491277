import * as Csm from "./iHostWebApiInterfaces.Csm"
import * as Customers from "./iHostWebApiInterfaces.Customer";
import * as Enums from "./iHostWebApiInterfaces.Enums";
import * as Inputs from "./iHostWebApiInterfaces.Inputs";
import * as Links from "./iHostWebApiInterfaces.Links";
import * as Networks from "./iHostWebApiInterfaces.Networks";
import * as Rtus from "./iHostWebApiInterfaces.Rtus";
import * as Scada from "./iHostWebApiInterfaces.Scada";
import * as System from "./iHostWebApiInterfaces.System";
import * as TaskManager from "./iHostWebApiInterfaces.TaskManager"

export class QueryStrings {

    public static getQueryStringVals(caseSensitive: boolean = false, location: string = window.location.href) {
        const vars: { [key: string]: string } = {};
        const hashIndex = location.lastIndexOf("#");
        let queryStringArgs: string[];
        if (hashIndex !== -1) {
            queryStringArgs = location.slice(location.indexOf("?") + 1, hashIndex).split("&");
        } else {
            queryStringArgs = location.slice(location.indexOf("?") + 1).split("&");
        }
        for (const queryString of queryStringArgs) {
            const queryStringSegments = queryString.split("=");
            const key = caseSensitive ? queryStringSegments[0] : queryStringSegments[0].toLowerCase();
            
            if (vars[key]) {
                vars[key] += "," + decodeURIComponent(queryStringSegments[1]);
            } else {
                vars[key] = decodeURIComponent(queryStringSegments[1]);
            }
        }
        return vars;
    }

    public static getQueryStringVal(name: string, caseSensitive: boolean = false, location?: string): string {
        return QueryStrings.getQueryStringVals(caseSensitive, location)[caseSensitive ? name : name.toLowerCase()];
    }

    public static hasQueryStringKey(name: string, caseSensitive: boolean = false, location?: string) {
        return (caseSensitive ? name : name.toLowerCase()) in QueryStrings.getQueryStringVals(caseSensitive, location);
    }
}

export class LinkHelper {

    private static RtuOfflineReason = "RTU is offline or not connected and cannot be polled";
    private static NoPermission = "You do not have permission to access this link";

    /**
     * Determines if the links should be shown for the RTU
     * @param rtuLinks all links for the RTU
     * @param rtu the RTU the links belong to
     * @param rtuStatus the current RTU status
     * @returns whether each link should be shown
     */
    public static shouldShowRtuLinks(rtuLinks: Links.RtuLinks, rtu: Rtus.Rtu, rtuStatus: Rtus.RtuStatus): Links.RtuLinksVisibleResult {
        const result: Links.RtuLinksVisibleResult = {
            CallNow: {
                link: rtuLinks.CallNow,
                reason: LinkHelper.hasPermission(rtuLinks.CallNow) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.CallNow),
            },
            RTUEventPage: {
                link: rtuLinks.RTUEventPage,
                reason: LinkHelper.hasPermission(rtuLinks.RTUEventPage) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUEventPage),
            },
            RTUMapView: {
                link: rtuLinks.RTUMapView,
                reason: LinkHelper.hasPermission(rtuLinks.RTUMapView) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUMapView),
            },
            RTUOverviewOrMimic: {
                link: rtuLinks.RTUOverviewOrMimic,
                reason: LinkHelper.hasPermission(rtuLinks.RTUOverviewOrMimic) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUOverviewOrMimic),
            },
            RTUOverviewPage: {
                link: rtuLinks.RTUOverviewPage,
                reason: LinkHelper.hasPermission(rtuLinks.RTUOverviewPage) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUOverviewPage),
            },
            RTUSettingsPage: {
                link: rtuLinks.RTUSettingsPage,
                reason: LinkHelper.hasPermission(rtuLinks.RTUSettingsPage) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUSettingsPage),
            },
            RTUDiagramPage: {
                link: rtuLinks.RTUDiagramPage,
                reason: LinkHelper.hasPermission(rtuLinks.RTUDiagramPage) !== false ?
                    null : LinkHelper.NoPermission,
                showLink: LinkHelper.hasPermission(rtuLinks.RTUDiagramPage),
            },
        };

        if (result.CallNow && result.CallNow.showLink && rtu.ProtocolId === Enums.Protocol.DNP3) {
            if (!rtuStatus.Online || rtuStatus.CommsStatusId !== Enums.RtuCommsStatus.Connected) {
                result.CallNow.reason = LinkHelper.RtuOfflineReason;
                result.CallNow.showLink = false;
            }
        }

        return result;
    }

    /**
     * Gets the URL for a system link, with query arguments.
     * @param link The system link.
     * @param queryArguments The query arguments for the link, as a object dictionary.
     * @returns The URL, with query arguments.
     */
    public static getUrlWithQueryArguments(link: Links.SystemLink, queryArguments: { [key: string]: any }) {
        // Translates the query arguments to the equivelant key value pairs.
        // e.g. { CustomerId: 1, Filter: "1" } could (dependent on the link) produce ["cust_id=1", "filter=1"].
        const queryKeyValuePairs: string[] = [];
        if (link.QueryParameters) {
            for (const key in queryArguments) {
                if (link.QueryParameters.hasOwnProperty(key)) {
                    queryKeyValuePairs.push(`${link.QueryParameters[key]}=${encodeURIComponent(queryArguments[key])}`);
                }
            }
        }

        // Combines the URL and query key value pairs.
        let finalUrl = link.Url;
        if (queryKeyValuePairs.length > 0) {
            const separator = link.Url.indexOf("?") === -1 ? "?" : "&";
            finalUrl += separator + queryKeyValuePairs.join("&");
        }
        return finalUrl;
    }

    private static hasPermission(link: Links.Link) {
        if (!link) {
            return false;
        }
        return !link.PermissionRequired || link.HasPermission;
    }

}

export class CoordinateFormatter {
    /**
     * Gets a latitude which has been formatted to a display format.
     * @param latitude The latitude to format.
     * @param displayFormat The display format to use.
     * @returns A formatted latitude.
     */
    public static formatLatitude(latitude: number, displayFormat: Enums.LatLongPosition) {
        switch (displayFormat) {
            case Enums.LatLongPosition.DecimalDegrees:
                return latitude.toFixed(6).toString();
            case Enums.LatLongPosition.DecimalDegreesHemisphere:
                return Math.abs(latitude).toFixed(6) + (latitude > 0 ? "N" : "S");
            case Enums.LatLongPosition.DegreesDecimalMinutes:
                const absLatitudeMins = Math.abs(latitude);
                return Math.floor(absLatitudeMins) + ":" +
                    ((absLatitudeMins % 1) * 60).toFixed(5) +
                    (latitude > 0 ? "N" : "S");
            case Enums.LatLongPosition.DegreesMinutesSeconds:
                const absLatitudeSecs = Math.abs(latitude);
                const minutes = (absLatitudeSecs % 1) * 60;
                return Math.floor(absLatitudeSecs) + ":" +
                    Math.floor(minutes) + ":" +
                    ((minutes % 1) * 60).toFixed(3) + (latitude > 0 ? "N" : "S");
            default:
                return latitude.toFixed(6).toString();
        }
    }

    /**
     * Gets a longitude which has been formatted to a display format.
     * @param longitude The longitude to format.
     * @param displayFormat The display format to use.
     * @returns A formatted latitude.
     */
    public static formatLongitude(longitude: number, displayFormat: Enums.LatLongPosition) {
        switch (displayFormat) {
            case Enums.LatLongPosition.DecimalDegrees:
                return longitude.toFixed(6).toString();
            case Enums.LatLongPosition.DecimalDegreesHemisphere:
                return Math.abs(longitude).toFixed(6) + (longitude > 0 ? "E" : "W");
            case Enums.LatLongPosition.DegreesDecimalMinutes:
                const absLongitudeMins = Math.abs(longitude);
                return Math.floor(absLongitudeMins) + ":" +
                    ((absLongitudeMins % 1) * 60).toFixed(5) +
                    (longitude > 0 ? "E" : "W");
            case Enums.LatLongPosition.DegreesMinutesSeconds:
                const absLongitudeSec = Math.abs(longitude);
                const minutes = (absLongitudeSec % 1) * 60;
                return Math.floor(absLongitudeSec) + ":" +
                    Math.floor(minutes) + ":" +
                    ((minutes % 1) * 60).toFixed(3) +
                    (longitude > 0 ? "E" : "W");
            default:
                return longitude.toFixed(6).toString();
        }
    }
}

export class PointHelper {
    public static get digitalInputIoReferenceOffset() {
        return 1;
    }
    public static get digitalInputIoReferenceMax() {
        return 1000;
    }
    public static get binaryInputIoReferenceOffset() {
        return 1;
    }
    public static get binaryInputIoReferenceMax() {
        return 1000;
    }
    public static get analogueInputIoReferenceOffset() {
        return 1001;
    }
    public static get analogueInputIoReferenceMax() {
        return 2000;
    }
    public static get systemPointIoReferenceOffset() {
        return 2001;
    }
    public static get systemPointIoReferenceMax() {
        return 3000;
    }
    public static get binaryOutputIoReferenceOffset() {
        return 3001;
    }
    public static get binaryOutputIoReferenceMax() {
        return 4000;
    }
    public static get counterInputIoReferenceOffset() {
        return 4001;
    }
    public static get counterInputIoReferenceMax() {
        return 5000;
    }
    public static get stringInputIoReferenceOffset() {
        return 5001;
    }
    public static get stringInputIoReferenceMax() {
        return 6000;
    }
    public static get analogueOutputIoReferenceOffset() {
        return 6001;
    }
    public static get analogueOutputIoReferenceMax() {
        return 7000;
    }
    public static get doubleBitInputIoReferenceOffset() {
        return 7001;
    }
    public static get doubleBitInputIoReferenceMax() {
        return 8000;
    }
    public static get eventSourceIoReference() {
        return 2001;
    }
    public static get rtuBatteryIoReference() {
        return 2002;
    }
    public static get rtuPowerIoReference() {
        return 2003;
    }
    public static get rssiIoReference() {
        return 2004;
    }
    public static get fpiStatusIoReference() {
        return 2007;
    }
    public static get positionIoReference() {
        return 2008;
    }
    public static get ignitionIoReference() {
        return 2009;
    }
    public static get rtuTemperature() {
        return 1901;
    }

    /**
     * Gets the point sub type for a given io reference.
     * @param ioReference The RTU IO reference.
     * @param protocolId The RTU protocol.
     * @returns Point Sub Type.
     * @throws Exception when invalid IO Reference supplied
     */
    public static getPointSubType(ioReference: number, protocolId: Enums.Protocol): Enums.PointSubType {
        if (ioReference >= this.binaryInputIoReferenceOffset && ioReference <= this.binaryInputIoReferenceMax) {
            if (protocolId === Enums.Protocol.Nexus) {
                throw new Error("Cannot determine Point Sub Type from provided IO Reference");
            }
            return Enums.PointSubType.BinaryInput;
        } else if (ioReference >= this.binaryOutputIoReferenceOffset
            && ioReference <= this.binaryOutputIoReferenceMax) {
            return Enums.PointSubType.BinaryOutput;
        } else if (ioReference >= this.counterInputIoReferenceOffset
            && ioReference <= this.counterInputIoReferenceMax) {
            return Enums.PointSubType.CounterInput;
        } else if (ioReference >= this.analogueInputIoReferenceOffset
            && ioReference <= this.analogueInputIoReferenceMax) {
            return Enums.PointSubType.AnalogueInput;
        } else if (ioReference >= this.analogueOutputIoReferenceOffset
            && ioReference <= this.analogueOutputIoReferenceMax) {
            return Enums.PointSubType.AnalogueOutput;
        } else if (ioReference >= this.stringInputIoReferenceOffset
            && ioReference <= this.stringInputIoReferenceMax) {
            return Enums.PointSubType.StringInput;
        } else if (ioReference >= this.doubleBitInputIoReferenceOffset
            && ioReference <= this.doubleBitInputIoReferenceMax) {
            return Enums.PointSubType.DoubleBitInput;
        } else if (ioReference === this.eventSourceIoReference) {
            return Enums.PointSubType.RtuEventSource;
        } else if (ioReference === this.rtuBatteryIoReference) {
            return Enums.PointSubType.RtuBattery;
        } else if (ioReference === this.rtuPowerIoReference) {
            return Enums.PointSubType.RtuPower;
        } else if (ioReference === this.rssiIoReference) {
            return Enums.PointSubType.Rssi;
        } else if (ioReference === this.fpiStatusIoReference) {
            return Enums.PointSubType.FpiStatus;
        } else if (ioReference === this.positionIoReference) {
            return Enums.PointSubType.GpsPosition;
        } else if (ioReference === this.ignitionIoReference) {
            return Enums.PointSubType.Ignition;
        } else {
            throw new Error("Invalid IO reference");
        }
    }

    public static isPointOverRange(analogueInput: Inputs.AnalogueInput) {
        return PointHelper.isOverRange(analogueInput.DisplayValue, analogueInput.Scale);
    }

    public static isOverRange(displayValue: number, scale: Inputs.AnalogueScale) {
        return displayValue > scale.DisplayMax || displayValue < scale.DisplayMin;
    }

    /**
     * Gets the units if applicable for the given point.
     */
    public static getUnits(input: Inputs.Input): string {
        switch (input.PointSubTypeId) {
            case Enums.PointSubType.AnalogueInput:
            case Enums.PointSubType.AnalogueOutput:
            case Enums.PointSubType.CounterInput:
            case Enums.PointSubType.Rssi:
                return (input as Inputs.AnaloguePoint | Inputs.CounterInput | Inputs.RssiPoint).Units;
            default:
                return "";
        }
    }

    /**
    * Gets the display value if applicable for the given point.
    */
    public static getDisplayValue(input: Inputs.Input): string {
        switch (input.PointSubTypeId) {
            case Enums.PointSubType.AnalogueInput:
            case Enums.PointSubType.CounterInput:
                return (input as Inputs.AnalogueInput | Inputs.CounterInput).DisplayValue.toString();
            case Enums.PointSubType.AnalogueOutput:
                return (input as Inputs.AnalogueOutput).ReadDisplayValue.toString();
            case Enums.PointSubType.Rssi:
                const rssi = (input as Inputs.RssiPoint);
                if (rssi.Value == null) return "Unknown"; // should never happen see case 23472.
                return rssi.Value.toString();
            case Enums.PointSubType.StringInput:
                return (input as Inputs.StringInput).LastValue;
            default:
                return "";
        }
    }

    /**
    * Gets the display status if applicable for the given point.
    */
    public static getDisplayStatus(input: Inputs.Input): string {
        switch (input.PointSubTypeId) {
            case Enums.PointSubType.AnalogueInput:
            case Enums.PointSubType.BinaryInput:
            case Enums.PointSubType.CounterInput:
            case Enums.PointSubType.BinaryOutput:
            case Enums.PointSubType.DoubleBitInput:
                return (input as Inputs.AnalogueInput | Inputs.BinaryInput | Inputs.CounterInput | Inputs.BinaryOutput | Inputs.DoubleBitInput).StatusText;
            default:
                return "";
        }
    }

    /**
    * Gets the display text for a point.
    */
    public static getDisplayValueText(input: Inputs.Input): string {
        if (!input.Enabled) return "Disabled";
        const displayText: string[] = [];

        const displayValue = PointHelper.getDisplayValue(input);
        if (displayValue) {
            displayText.push(displayValue);
        }
        const displayUnits = PointHelper.getUnits(input);
        if (displayUnits) {
            displayText.push(displayUnits);
        }
        const displayStatus = PointHelper.getDisplayStatus(input);
        if (displayStatus) {
            displayText.push(displayStatus);
        }
        return displayText.join(" ");
    }

    /**
     * A type guard for converting an Input to BinaryInput.
     * @param point The Input instance.
     */
    public static isBinaryInput(point: Inputs.Input): point is Inputs.BinaryInput {
        return point?.PointSubTypeId === Enums.PointSubType.BinaryInput;
    }

    /**
     * A type guard for converting an Input to BinaryOutput.
     * @param point The Input instance.
     */
    public static isBinaryOutput(point: Inputs.Input): point is Inputs.BinaryOutput {
        return point?.PointSubTypeId === Enums.PointSubType.BinaryOutput;
    }

    /**
     * A type guard for converting an Input to CounterInput.
     * @param point The Input instance.
     */
    public static isCounterInput(point: Inputs.Input): point is Inputs.CounterInput {
        return point?.PointSubTypeId === Enums.PointSubType.CounterInput;
    }

    /**
     * A type guard for converting an Input to AnalogueInput.
     * @param point The Input instance.
     */
    public static isAnalogueInput(point: Inputs.Input): point is Inputs.AnalogueInput {
        return point?.PointSubTypeId === Enums.PointSubType.AnalogueInput;
    }

    /**
     * A type guard for converting an Input to AnalogueOutput.
     * @param point The Input instance.
     */
    public static isAnalogueOutput(point: Inputs.Input): point is Inputs.AnalogueOutput {
        return point?.PointSubTypeId === Enums.PointSubType.AnalogueOutput;
    }

    /**
     * A type guard for converting an Input to StringInput.
     * @param point The Input instance.
     */
    public static isStringInput(point: Inputs.Input): point is Inputs.StringInput {
        return point?.PointSubTypeId === Enums.PointSubType.StringInput;
    }

    /**
     * A type guard for converting an Input to DoubleBitInput.
     * @param point The Input instance.
     */
    public static isDoubleBitInput(point: Inputs.Input): point is Inputs.DoubleBitInput {
        return point?.PointSubTypeId === Enums.PointSubType.DoubleBitInput;
    }

    /**
     * A type guard for converting an Input to MainsStatus.
     * @param point The Input instance.
     */
    public static isMainsStatusInput(point: Inputs.Input): point is Inputs.MainsStatus {
        return point?.PointSubTypeId === Enums.PointSubType.RtuPower;
    }

    public static getFullDisplayName(point: Inputs.Input): string {
        if (!point) {
            return "";
        }
        const abbreviation = EnumHelper.getPointSubTypeAbbreviation(point.PointSubTypeId);
        return (abbreviation ? `[${abbreviation}${point.PointNumber}] ${point.Name}` : point.Name) + (point.Name2 ? ` (${point.Name2})` : "");
    }
}

export class NetworkHelper {
    /**
     * A type guard for converting an NetworkElement to NetworkNode.
     * @param networkElement The NetworkElement instance.
     */
    public static isNetworkNode(networkElement: Networks.NetworkElement): networkElement is Networks.NetworkNode {
        return networkElement != null && "NetworkNodeUuid" in networkElement;
    }

    /**
     * A type guard for converting an NetworkElement to NetworkEdge.
     * @param networkElement The NetworkElement instance.
     */
    public static isNetworkEdge(networkElement: Networks.NetworkElement): networkElement is Networks.NetworkEdge {
        return networkElement != null && "NetworkEdgeUuid" in networkElement;
    }

    /**
     * A type guard for converting an NetworkElement to NetworkGroup.
     * @param networkElement The NetworkElement instance.
     */
    public static isNetworkGroup(networkElement: Networks.NetworkElement): networkElement is Networks.NetworkGroup {
        // Can't use NetworkGroupUuid as it's shared with NetworkNode and NetworkEdge.
        return networkElement != null && "NetworkGroupTypeId" in networkElement;
    }

    /**
     * A type guard for converting an NetworkElement to NetworkAnnotation.
     * @param networkElement The NetworkElement instance.
     */
    public static isNetworkAnnotation(networkElement: Networks.NetworkElement): networkElement is Networks.NetworkAnnotation {
        return networkElement != null && "NetworkAnnotationUuid" in networkElement;
    }
}

export class AlarmHelper {
     /**
     * Gets the alarm summary text.
     */
    public static getSummaryEnumName(summary: Enums.AlarmSummary): string {
        switch (summary) {
            case Enums.AlarmSummary.Healthy:
                return "Healthy";
            case Enums.AlarmSummary.AckedAlarm:
                return "Acknowledged Alarm";
            case Enums.AlarmSummary.UnackedAlarm:
                return "Unacknowledged Alarm";
            case Enums.AlarmSummary.Late:
                return "Late";
            case Enums.AlarmSummary.Parked:
                return "Parked/Disabled";
        }
    }

    /**
     * Gets the alarm summary for a point.
     * @param point A point with alarm state details.
     * @returns The alarm summary for the point.
     */
    public static getSummaryForPoint(point: Inputs.Input): Enums.AlarmSummary | undefined {
        if (point.AlarmState?.Summary === Enums.AlarmSummary.Healthy) {
            if (PointHelper.isBinaryInput(point) && point.DisplayTypeId === Enums.PointDisplayType.Status && point.Closed === point.NormallyOpen) {
                return Enums.AlarmSummary.AckedAlarm;
            }
            else if (PointHelper.isBinaryOutput(point) && point.LastReadValue) {
                return Enums.AlarmSummary.AckedAlarm;
            }
        }
        return point.AlarmState?.Summary;
    }
}

export class UserHelper {
    /**
     * Gets the display name for the given user.
     * @param user User details.
     * @param surnameFirst Flag to determine if surname should be displayed before firstname. Default is false.
     * @returns Display name for a user.
     */
    public static getUserDisplayName(user: Customers.BasicUserDetails, surnameFirst = false) {
        if (user.FirstName.length < 1 || user.LastName.length < 1) {
            return user.UserName;
        } else if (surnameFirst) {
            return `${user.LastName}, ${user.FirstName}`;
        } else {
            return `${user.FirstName} ${user.LastName}`;
        }
    }
}

export class RtuHelper {
    private static readonly OnlineApplicableProtocols: Enums.Protocol[] = [
        Enums.Protocol.DNP3,
        Enums.Protocol.Iec104,
        Enums.Protocol.ModbusTcp,
        Enums.Protocol.SelAscii,
        Enums.Protocol.ION,
        Enums.Protocol.MqttSparkplug,
    ];

    /**
     * Determines if an Protocol is online applicable
     * @returns Serial Number Prefix or an empty string if no prefix defined
     */
    public static isOnlineApplicable(protocol: Enums.Protocol) {
        return RtuHelper.OnlineApplicableProtocols.indexOf(protocol) >= 0;
    }    

    /**
     * Determines whether the RTU/Template has device settings.
     * @param rtu RTU
     * @returns Whether the RTU has device settings
     */
    public static hasDeviceSettings(rtu: Rtus.Rtu): boolean {
        return !rtu.Template ||
            rtu.RtuTemplateType === Enums.RtuTemplateType.CombinedTemplate ||
            rtu.RtuTemplateType === Enums.RtuTemplateType.DeviceTemplate;
    }

    /**
     * Gets the Serial Number prefix for a given RTU Type.
     * @returns Serial Number Prefix or an empty string if no prefix defined
     */
    public static getRtuSerialNumberPrefix(rtuType: Enums.RtuType) {
        switch (rtuType) {
            case Enums.RtuType.Nexus10:
                return "5010";
            case Enums.RtuType.Nexus11:
                return "5011";
            case Enums.RtuType.Nexus12:
                return "5012";
            case Enums.RtuType.Nexus20:
                return "5020";
            case Enums.RtuType.Nexus21:
                return "5021";
            case Enums.RtuType.Nexus22:
            case Enums.RtuType.Nexus22Dnp3:
                return "5022";
            case Enums.RtuType.Nexus30:
                return "5030";
            case Enums.RtuType.Nexus41:
                return "5041";
            case Enums.RtuType.Nexus42:
                return "5042";
            case Enums.RtuType.Nexus43:
                return "5043";
            case Enums.RtuType.Nexus44:
                return "5";
            case Enums.RtuType.Nexus45:
                return "5045";
            case Enums.RtuType.Nexus50:
                return "5050";
            case Enums.RtuType.Nexus52:
                return "5052";
            case Enums.RtuType.PQube:
                return "P";
            case Enums.RtuType.LandisGyrMeter:
                return "LGZ";
            case Enums.RtuType.ANMScheme:
                return "ANM-";
            case Enums.RtuType.VisnetHub:
                return "VNH1";
            default:
                return "";
        }
    }

    /**
     * Converts an RTU type to human readable string.
     */
    public static getRtuTypeString(rtuType: Enums.RtuType) {
        switch (rtuType) {
            case Enums.RtuType.Dataset:
                return "Dataset";
            case Enums.RtuType.Messenger61:
                return "Messenger 61";
            case Enums.RtuType.Messenger62:
                return "Messenger 62";
            case Enums.RtuType.Nexus10:
                return "NX10";
            case Enums.RtuType.SteppGSMGPS:
                return "STEPP GSM/GPS";
            case Enums.RtuType.Pod:
                return "POD";
            case Enums.RtuType.Nexus20:
                return "NX20";
            case Enums.RtuType.BowdenP360:
                return "Bowden P360 FPI";
            case Enums.RtuType.Signature:
                return "Signature";
            case Enums.RtuType.Nexus30:
                return "NX30";
            case Enums.RtuType.Nexus21:
                return "NX21";
            case Enums.RtuType.Nexus22:
                return "NX22";
            case Enums.RtuType.Nexus22Dnp3:
                return "NX22 DNP3";
            case Enums.RtuType.BowdenFaultWatch:
                return "Bowden Faultwatch";
            case Enums.RtuType.GridSenseLT40PAC:
                return "GridSense LT40 PAC";
            case Enums.RtuType.Kehui:
                return "Kehui LV Fault Locator";
            case Enums.RtuType.Nexus41:
                return "NX41";
            case Enums.RtuType.Nexus42:
                return "NX42";
            case Enums.RtuType.Nexus43:
                return "NX43";
            case Enums.RtuType.Nexus46:
                return "Polestar NX46";
            case Enums.RtuType.Nexus50:
                return "NX50";
            case Enums.RtuType.Nexus25:
                return "NX25";
            case Enums.RtuType.BowdenPowerWatch:
                return "Bowden Powerwatch";
            case Enums.RtuType.Nexus11:
                return "NX11";
            case Enums.RtuType.KelmanRezap:
                return "Kelvatek Rezap";
            case Enums.RtuType.SoapXml:
                return "SOAP XML";
            case Enums.RtuType.BowdenMK10:
                return "Bowden MK10";
            case Enums.RtuType.VistaSmartDisc:
                return "Vista SmartDisc";
            case Enums.RtuType.NexusEnvoy:
                return "Envoy Nexus";
            case Enums.RtuType.Subnet:
                return "sub.net";
            case Enums.RtuType.Nexus52:
                return "NX52";
            case Enums.RtuType.BowdenSigma:
                return "Bowden SIGMA";
            case Enums.RtuType.EnvoyDnp3:
                return "Envoy DNP3";
            case Enums.RtuType.GenericDnp3:
                return "Generic DNP3";
            case Enums.RtuType.GridKeyStatisticalMcu:
                return "GridKey Statistical MCU";
            case Enums.RtuType.GenericNX:
                return "Generic NX";
            case Enums.RtuType.NortrollLT3100:
                return "Nortroll LT 3100";
            case Enums.RtuType.NortrollLTR400D:
                return "Nortroll LT R400D";
            case Enums.RtuType.NortrollLTR110C:
                return "Nortroll LT R110C";
            case Enums.RtuType.Nexus81:
                return "NX81";
            case Enums.RtuType.PQube:
                return "PQube";
            case Enums.RtuType.GridKeyInstantaneousMcu:
                return "GridKey Instantaneous MCU";
            case Enums.RtuType.LandisGyrMeter:
                return "Landis+Gyr Meter";
            case Enums.RtuType.Nexus12:
                return "NX12";
            case Enums.RtuType.IPECPDAlarm:
                return "IPEC PD Alarm";
            case Enums.RtuType.ANMScheme:
                return "ANM Scheme";
            case Enums.RtuType.SMS:
                return "SMS";
            case Enums.RtuType.IccpServer:
                return "ICCP Server";
            case Enums.RtuType.IccpClient:
                return "ICCP Client";
            case Enums.RtuType.GridKeyLVCMMcu:
                return "GridKey LVCM MCU";
            case Enums.RtuType.LVCAPDevice:
                return "LV-CAP Device";
            case Enums.RtuType.LVCAPApplication:
                return "LV-CAP Application";
            case Enums.RtuType.Nexus44:
                return "NX44";
            case Enums.RtuType.Nexus45:
                return "NX45";
            case Enums.RtuType.NavigatorRS:
                return "Smart Navigator 2.0";
            case Enums.RtuType.PQMonitor:
                return "PQ Monitor";
            case Enums.RtuType.Iec104Outstation:
                return "IEC 104 Outstation";
            case Enums.RtuType.Nexus14:
                return "NX14";
            case Enums.RtuType.EneidaDtvig:
                return "Eneida DTVIg";
            case Enums.RtuType.ModbusSlave:
                return "Modbus Slave";
            case Enums.RtuType.MqttDevice:
                return "MQTT Device";
            case Enums.RtuType.RX10:
                return "RX10";
            case Enums.RtuType.EConnectEVChargingStation:
                return "E.Connect EV Charging Station";
            case Enums.RtuType.SEL735:
                return "SEL-735";
            case Enums.RtuType.ION7650:
                return "ION7650";
            case Enums.RtuType.FlexiblePowerDispatchGroup:
                return "Flexible Power Dispatch Group";
            case Enums.RtuType.MqttSparkplugDevice:
                return "MQTT Sparkplug Device";
            case Enums.RtuType.MqttSparkplugNode:
                return "MQTT Sparkplug Node";
            case Enums.RtuType.PoleMaster:
                return "Pole Master";
            case Enums.RtuType.PoleMasterRail:
                return "Pole Master Rail";
            case Enums.RtuType.SmartReceiverDnp3:
                return "Smart Receiver DNP3 4.0";
            case Enums.RtuType.VisnetHub:
                return "VisNet Hub";
            case Enums.RtuType.HAYSYSFeederNet:
                return "HAYSYS FeederNet";
            case Enums.RtuType.Comms: 
                return "Comms Template";
            default:
                return "Unknown";
        }
    }

    public static getSupportedRtuFileFormats(rtuType: Enums.RtuType) {
        let supportedFormats = [
            Enums.RtuFileFormat.CsvRtuEvents,
            Enums.RtuFileFormat.RtuDataArchive,
            Enums.RtuFileFormat.CommissioningDatalog,
            Enums.RtuFileFormat.EventRecordingComtradeWaveform,
            Enums.RtuFileFormat.EventRecordingComtradeRms
        ];

        if (rtuType === Enums.RtuType.NexusEnvoy || rtuType === Enums.RtuType.EnvoyDnp3) {
            return [...supportedFormats, Enums.RtuFileFormat.EnvoyCsvDataLogs];
        }

        supportedFormats.push(Enums.RtuFileFormat.CsvEvents);

        if (this.isNexusDnp3Unit(rtuType)) {
            supportedFormats.push(Enums.RtuFileFormat.NexusBinaryStatus);
        }

        switch (rtuType) {
            case Enums.RtuType.LVCAPApplication:
            case Enums.RtuType.LVCAPDevice:
                return [
                    ...supportedFormats,
                    Enums.RtuFileFormat.LVCapJsonDataLogs
                ];
            case Enums.RtuType.PQube:
                return [
                    ...supportedFormats,
                    Enums.RtuFileFormat.PqubeCsvDataLogs
                ];
            case Enums.RtuType.PQMonitor:
                return [
                    ...supportedFormats,
                    Enums.RtuFileFormat.PQDataArchivePQube3,
                    Enums.RtuFileFormat.CsvPQDataLogsPQube3,
                    Enums.RtuFileFormat.XmlPQEventPQube3,
                    Enums.RtuFileFormat.EventRecordingPQube3CsvWaveform,
                    Enums.RtuFileFormat.EventRecordingPQube3CsvRms,
                    Enums.RtuFileFormat.CsvPQDataLogsAeberle,
                    Enums.RtuFileFormat.CsvPQEventsAeberle,
                    Enums.RtuFileFormat.PQDataArchiveSiemensQ100,
                    Enums.RtuFileFormat.PQDataArchiveSiemensQ200,
                    Enums.RtuFileFormat.PQDataArchiveSiemensSiprotec7ke85,
                    Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ100,
                    Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ200,
                    Enums.RtuFileFormat.CsvPQEventsSiemens,
                    Enums.RtuFileFormat.EventRecordingArchiveComtrade
                ];
            case Enums.RtuType.NavigatorRS:
            case Enums.RtuType.PoleMaster:
            case Enums.RtuType.PoleMasterRail:
                return [
                    ...supportedFormats,
                    Enums.RtuFileFormat.SmartNavigator2CsvWaveform
                ];
            case Enums.RtuType.Dataset:
                return [
                    ...supportedFormats,
                    Enums.RtuFileFormat.NgesoPerfmonV1,
                    Enums.RtuFileFormat.NgesoAvailabilityRedeclarationV1,
                    Enums.RtuFileFormat.NgesoBaselineDeclarationV1
                ];
            default:
                return supportedFormats;
        }
    }

    public static isNexusDnp3Unit(rtuType: Enums.RtuType): boolean {
        switch (rtuType) {
            case Enums.RtuType.Nexus22Dnp3:
            case Enums.RtuType.Nexus44:
            case Enums.RtuType.Nexus45: 
            case Enums.RtuType.Nexus14:
            case Enums.RtuType.RX10:
                return true;
            default:
                return false;
        }
    }

    public static getSupportedFileExtensionsForProcessing(rtuFileFormat: Enums.RtuFileFormat) {
        switch (rtuFileFormat) {
            case Enums.RtuFileFormat.CsvEvents:
            case Enums.RtuFileFormat.EnvoyCsvDataLogs:
            case Enums.RtuFileFormat.PqubeCsvDataLogs:
            case Enums.RtuFileFormat.CsvPQDataLogsPQube3:
            case Enums.RtuFileFormat.CsvPQDataLogsAeberle:
            case Enums.RtuFileFormat.CsvPQEventsAeberle:
            case Enums.RtuFileFormat.CsvPQEventsSiemens:
            case Enums.RtuFileFormat.CsvRtuEvents:
                return [".csv", ".zip", ".csv.zip", ".csv.gz", ".csv.bz2"];
            case Enums.RtuFileFormat.LVCapJsonDataLogs:
                return [".json"]
            case Enums.RtuFileFormat.CommissioningDatalog:
                return [".ncz"];
            case Enums.RtuFileFormat.PQDataArchivePQube3:
            case Enums.RtuFileFormat.PQDataArchiveSiemensQ100:
            case Enums.RtuFileFormat.PQDataArchiveSiemensQ200:
            case Enums.RtuFileFormat.PQDataArchiveSiemensSiprotec7ke85:
            case Enums.RtuFileFormat.EventRecordingArchiveComtrade:
                return [".tar.bz2"]
            case Enums.RtuFileFormat.EventRecordingPQube3CsvWaveform:
            case Enums.RtuFileFormat.EventRecordingPQube3CsvRms:
            case Enums.RtuFileFormat.NgesoPerfmonV1:
            case Enums.RtuFileFormat.NgesoAvailabilityRedeclarationV1:
            case Enums.RtuFileFormat.NgesoBaselineDeclarationV1:
                return [".csv"];
            case Enums.RtuFileFormat.XmlPQEventPQube3:
                return [".xml", ".xml.bz2", ".xml.gz"]
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ100:
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ200:
                return [".pqd"];
            case Enums.RtuFileFormat.EventRecordingComtradeWaveform:
            case Enums.RtuFileFormat.EventRecordingComtradeRms:
                return [".cff",".cfg"];
            case Enums.RtuFileFormat.RtuDataArchive:
                return [".zip"];
            case Enums.RtuFileFormat.NexusBinaryStatus:
                return [".nxb"];
            case Enums.RtuFileFormat.SmartNavigator2CsvWaveform:
                return [".wvf"];
            default:
                    return [];
        }
    }

    public static getSupportedRtuFileValueFormatsForProcessing(rtuFileFormat: Enums.RtuFileFormat) {
        switch (rtuFileFormat) {
            case Enums.RtuFileFormat.PqubeCsvDataLogs:
            case Enums.RtuFileFormat.LVCapJsonDataLogs:
            case Enums.RtuFileFormat.CsvPQDataLogsPQube3:
            case Enums.RtuFileFormat.CsvPQDataLogsAeberle:
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ100:
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ200:
            case Enums.RtuFileFormat.NgesoPerfmonV1:
            case Enums.RtuFileFormat.NgesoAvailabilityRedeclarationV1:
            case Enums.RtuFileFormat.NgesoBaselineDeclarationV1:
                return [Enums.RtuFileValueFormat.Real];
            case Enums.RtuFileFormat.EnvoyCsvDataLogs:
                return [Enums.RtuFileValueFormat.Protocol];
            case Enums.RtuFileFormat.CsvEvents:
                return [Enums.RtuFileValueFormat.Protocol, Enums.RtuFileValueFormat.Raw, Enums.RtuFileValueFormat.Real];
            default:
                return [Enums.RtuFileValueFormat.None];
        }
    }

    public static supportsLogicPoints(protocol: Enums.Protocol, rtuType: Enums.RtuType): boolean {
        if (rtuType === Enums.RtuType.Dataset) 
            return true;

        switch (protocol) {
            case Enums.Protocol.Nexus:
            case Enums.Protocol.BowdenFPI:
            case Enums.Protocol.Iccp:
            case Enums.Protocol.DNP3:
            case Enums.Protocol.Iec104:
            case Enums.Protocol.Mqtt:
            case Enums.Protocol.DeepGrid:
            case Enums.Protocol.ModbusTcp:
            case Enums.Protocol.EConnectApi:
            case Enums.Protocol.FlexiblePowerApi:
                return true;
            default:
                return false;
        }
    }

    public static supportsRegularDeviceApiCallInSettings(rtuType: Enums.RtuType): boolean {
        switch (rtuType) {
            case Enums.RtuType.Nexus22:
            case Enums.RtuType.Nexus22Dnp3:
            case Enums.RtuType.Nexus44:
            case Enums.RtuType.Nexus45:
            case Enums.RtuType.EnvoyDnp3:
            case Enums.RtuType.GenericDnp3:
            case Enums.RtuType.RX10:
            case Enums.RtuType.Iec104Outstation:
            case Enums.RtuType.MqttDevice:
            case Enums.RtuType.PQube:
            case Enums.RtuType.LandisGyrMeter:
            case Enums.RtuType.Dataset:
            case Enums.RtuType.NavigatorRS:
            case Enums.RtuType.PoleMaster:
            case Enums.RtuType.PoleMasterRail:
            case Enums.RtuType.VisnetHub:
            case Enums.RtuType.HAYSYSFeederNet:
                return true;
            default:
                return false;
        }
    }    

    public static getSupportedFileExtensionsForRtuBulkImportFormat(importFormat: Enums.RtuBulkImportFormat) {
        switch (importFormat) {
            case Enums.RtuBulkImportFormat.Csv:
                return ['.csv'];
            case Enums.RtuBulkImportFormat.VisnetEnrollmentPackage:
                return ['.zip'];
            default:
                    return [];
        }
    }
}

export class CertificateHelper {
    /**
     * Converts an Certificate type to human readable string.
     */
    public static getCertificateTypeString(certificateType: Enums.CertificateType) {
        switch (certificateType) {
            case Enums.CertificateType.Trusted:
                return "Trusted";
            case Enums.CertificateType.Signing:
                return "Signing";
            case Enums.CertificateType.Server:
                return "Server";
            case Enums.CertificateType.RTU:
                return "RTU";
            default:
                return "Unknown";
        }
    }
    /**
     * Converts an Certificate status to human readable string.
     */
    public static getCertificateStatusString(certificateStatus: Enums.CertificateStatus) {
        switch (certificateStatus) {
            case Enums.CertificateStatus.Valid:
                return "Valid";
            case Enums.CertificateStatus.Renew:
                return "Renew";
            default:
                return "Unknown";
        }
    }
    /**
     * Converts an Certificate signature algorithm to human readable string.
     */
    public static getCertificateSignatureAlgorithmString(signatureAlgorithm: Enums.SignatureAlgorithm) {
        switch (signatureAlgorithm) {
            case Enums.SignatureAlgorithm.Sha1:
                return "SHA1";
            case Enums.SignatureAlgorithm.Sha256:
                return "SHA256";
            default:
                return "Unknown";
        }
    }
}

export class AggregationModeHelper {
    /**
     * Converts an Aggregation Mode to human readable string.
     */
    public static getAggregationModeString(aggregationMode: Enums.AggregationMode) {
        switch (aggregationMode) {
            case Enums.AggregationMode.First:
                return "First";
            case Enums.AggregationMode.Last:
                return "Last";
            case Enums.AggregationMode.Min:
                return "Min";
            case Enums.AggregationMode.Max:
                return "Max";
            case Enums.AggregationMode.Avg:
                return "Avg";
            case Enums.AggregationMode.Median:
                return "Median";
            case Enums.AggregationMode.Sum:
                return "Sum";
            case Enums.AggregationMode.Range:
                return "Range";
            case Enums.AggregationMode.Diff:
                return "Diff";
            case Enums.AggregationMode.Count:
                return "Count";
            case Enums.AggregationMode.SumSquares:
                return "Sum of Squares";
            case Enums.AggregationMode.Var:
                return "Variance";
            case Enums.AggregationMode.StdDev:
                return "Standard Deviation";
            case Enums.AggregationMode.Iqr:
                return "Interquartile Range";
            case Enums.AggregationMode.Mult:
                return "Mult";
            case Enums.AggregationMode.Div:
                return "Div";
            case Enums.AggregationMode.Integral:
                return "Integral";
            case Enums.AggregationMode.StepIntegral:
                return "Step Integral";
            case Enums.AggregationMode.Percentile95:
                return "95th Percentile";
            case Enums.AggregationMode.Percentile5:
                return "5th Percentile";
            case Enums.AggregationMode.Percentile:
                return "Percentile";
            default:
                return "Unknown";
        }
    }
}

export class IndicatorHelper {
    /**
     * Converts an Indicator to human readable string.
     */
    public static getIndicatorString(indicator: Enums.Indicator) {
        switch (indicator) {
            case Enums.Indicator.None:
                return "None";
            case Enums.Indicator.Circle:
                return "Circle";
            case Enums.Indicator.Square:
                return "Square";
            case Enums.Indicator.Diamond:
                return "Diamond";
            case Enums.Indicator.Triangle:
                return "Triangle";
            case Enums.Indicator.Cross:
                return "Cross";
            default:
                return "Unknown";
        }
    }
}

export class LineStyleHelper {
    /**
     * Converts an Line Style to human readable string.
     */
    public static getLineStyleString(lineStyle: Enums.LineStyle) {
        switch (lineStyle) {
            case Enums.LineStyle.Solid:
                return "Solid";
            case Enums.LineStyle.Dashed:
                return "Dashed";
            case Enums.LineStyle.Dotted:
                return "Dotted";
            default:
                return "Unknown";
        }
    }
}

export class ScadaHelper {
    public static readonly ScadaProtocols: Enums.ScadaProtocol[] = [
        Enums.ScadaProtocol.IEC_60870_5_101,
        Enums.ScadaProtocol.IEC_60870_5_104,
        Enums.ScadaProtocol.DNP3,
        Enums.ScadaProtocol.IEC_61850,
        Enums.ScadaProtocol.Kafka,
        Enums.ScadaProtocol.FlexiblePowerApi,
        Enums.ScadaProtocol.MqttSparkplug
    ];

    /**
     * Converts an Scada Protocol to human readable string.
     */
    public static getScadaProtocolString(protocolId: Enums.ScadaProtocol) {
        switch (protocolId) {
            case Enums.ScadaProtocol.DNP3:
                return "DNP3";
            case Enums.ScadaProtocol.IEC_60870_5_101:
                return "IEC 60870-5-101";
            case Enums.ScadaProtocol.IEC_60870_5_104:
                return "IEC 60870-5-104";
            case Enums.ScadaProtocol.IEC_61850:
                return "IEC 61850";
            case Enums.ScadaProtocol.Kafka:
                return "Kafka";
            case Enums.ScadaProtocol.FlexiblePowerApi:
                return "Flexible Power API";
            case Enums.ScadaProtocol.MqttSparkplug:
                return "MQTT Sparkplug";
            default:
                return "Unknown";
        }
    }

    /**
     * Converts an Scada Comms Type to human readable string.
     */
    public static getScadaCommsTypeString(commsTypeId: Enums.ScadaCommsType) {
        switch (commsTypeId) {
            case Enums.ScadaCommsType.Serial:
                return "Serial";
            case Enums.ScadaCommsType.TcpIp:
                return "TCP/IP";
            default:
                return "Unknown";
        }
    }
}

export class SystemServiceHelper {
    /**
     * A type guard for converting a SystemService to Csm.
     * @param systemService The SystemService instance.
     */
    public static isCsm(systemService: System.SystemService): systemService is Csm.Csm {
        return systemService?.ServiceType === Enums.SystemServiceType.Csm;
    }

    /**
     * A type guard for converting a SystemService to TaskManager.
     * @param systemService The SystemService instance.
     */
    public static isTaskManager(systemService: System.SystemService): systemService is TaskManager.TaskManager {
        return systemService?.ServiceType === Enums.SystemServiceType.TaskManager;
    }
    
    /**
     * A type guard for converting a SystemService to ScadaInterface.
     * @param systemService The SystemService instance.
     */
    public static isScadaInterface(systemService: System.SystemService): systemService is Scada.ScadaInterface {
        return systemService?.ServiceType === Enums.SystemServiceType.ScadaInterface;
    }
}

export class EnumHelper {

    public static readonly Protocols = [
        Enums.Protocol.BowdenFPI,
        Enums.Protocol.DeepGrid,
        Enums.Protocol.DNP3,
        Enums.Protocol.EConnectApi,
        Enums.Protocol.FlexiblePowerApi,
        Enums.Protocol.GridKey,
        Enums.Protocol.Iccp,
        Enums.Protocol.Iec104,
        Enums.Protocol.iHostSOAPXML,
        Enums.Protocol.ION,
        Enums.Protocol.IPECPDAlarmSMS,
        Enums.Protocol.Kehui,
        Enums.Protocol.KelmanSOAPXML,
        Enums.Protocol.ModbusTcp,
        Enums.Protocol.Mqtt,
        Enums.Protocol.MqttSparkplug,
        Enums.Protocol.Nexus,
        Enums.Protocol.Nortroll,
        Enums.Protocol.SelAscii,
        Enums.Protocol.SubNet,
        Enums.Protocol.None
    ];

    public static readonly RtuCommsTypes = [
        Enums.RtuCommsType.CircuitSwitchedData,
        Enums.RtuCommsType.Dialup,
        Enums.RtuCommsType.DirectSerial,
        Enums.RtuCommsType.Icmp,
        Enums.RtuCommsType.SMS,
        Enums.RtuCommsType.Soap,
        Enums.RtuCommsType.TcpIp,
        Enums.RtuCommsType.TcpIpRing,
        Enums.RtuCommsType.TcpIpSms,
        Enums.RtuCommsType.UdpIp,
        Enums.RtuCommsType.None
    ];

    public static readonly RtuTypes = [
        Enums.RtuType.ANMScheme,
        Enums.RtuType.BowdenFaultWatch,
        Enums.RtuType.BowdenMK10,
        Enums.RtuType.BowdenP360,
        Enums.RtuType.BowdenPowerWatch,
        Enums.RtuType.BowdenSigma,
        Enums.RtuType.Comms,
        Enums.RtuType.Dataset,
        Enums.RtuType.EConnectEVChargingStation,
        Enums.RtuType.EneidaDtvig,
        Enums.RtuType.EnvoyDnp3,
        Enums.RtuType.FlexiblePowerDispatchGroup,
        Enums.RtuType.GenericDnp3,
        Enums.RtuType.GridKeyInstantaneousMcu,
        Enums.RtuType.GridKeyLVCMMcu,
        Enums.RtuType.GridKeyStatisticalMcu,
        Enums.RtuType.GridSenseLT40PAC,
        Enums.RtuType.HAYSYSFeederNet,
        Enums.RtuType.ION7650,
        Enums.RtuType.IccpClient,
        Enums.RtuType.IccpServer,
        Enums.RtuType.Iec104Outstation,
        Enums.RtuType.Kehui,
        Enums.RtuType.KelmanRezap,
        Enums.RtuType.LVCAPApplication,
        Enums.RtuType.LVCAPDevice,
        Enums.RtuType.LandisGyrMeter,
        Enums.RtuType.ModbusSlave,
        Enums.RtuType.MqttDevice,
        Enums.RtuType.MqttSparkplugDevice,
        Enums.RtuType.MqttSparkplugNode,
        Enums.RtuType.NavigatorRS,
        Enums.RtuType.Nexus10,
        Enums.RtuType.Nexus11,
        Enums.RtuType.Nexus12,
        Enums.RtuType.Nexus14,
        Enums.RtuType.Nexus20,
        Enums.RtuType.Nexus21,
        Enums.RtuType.Nexus22,
        Enums.RtuType.Nexus22Dnp3,
        Enums.RtuType.Nexus30,
        Enums.RtuType.Nexus41,
        Enums.RtuType.Nexus42,
        Enums.RtuType.Nexus43,
        Enums.RtuType.Nexus44,
        Enums.RtuType.Nexus45,
        Enums.RtuType.Nexus50,
        Enums.RtuType.Nexus52,
        Enums.RtuType.NexusEnvoy,
        Enums.RtuType.PQMonitor,
        Enums.RtuType.PQube,
        Enums.RtuType.Pod,
        Enums.RtuType.PoleMaster,
        Enums.RtuType.PoleMasterRail,
        Enums.RtuType.RX10,
        Enums.RtuType.SEL735,
        Enums.RtuType.SMS,
        Enums.RtuType.SmartReceiverDnp3,
        Enums.RtuType.SteppGSMGPS,
        Enums.RtuType.Subnet,
        Enums.RtuType.VisnetHub,
        Enums.RtuType.VistaSmartDisc,
    ];

    public static readonly PointSubTypes = [
        Enums.PointSubType.BinaryInput,
        Enums.PointSubType.BinaryOutput,
        Enums.PointSubType.CounterInput,
        Enums.PointSubType.AnalogueInput,
        Enums.PointSubType.AnalogueOutput,
        Enums.PointSubType.RtuBattery,
        Enums.PointSubType.RtuPower,
        Enums.PointSubType.Rssi,
        Enums.PointSubType.RtuEventSource,
        Enums.PointSubType.GpsPosition,
        Enums.PointSubType.RtuStatus,
        Enums.PointSubType.RtuTemperature,
        Enums.PointSubType.FpiStatus,
        Enums.PointSubType.Signature,
        Enums.PointSubType.Ignition,
        Enums.PointSubType.StringInput,
        Enums.PointSubType.DoubleBitInput,
    ];

    public static readonly EventLogTypes = [
        Enums.EventType.OtherUnknown,
        Enums.EventType.CommsDeviceFailedtoRespond,
        Enums.EventType.NetworkUnavailable,
        Enums.EventType.HostBusy,
        Enums.EventType.NoCarrierOnCommsdevice,
        Enums.EventType.DialToConnectTimerElapsed,
        Enums.EventType.PacketWaitTimerElapsed,
        Enums.EventType.CatchAllTimerElapsed,
        Enums.EventType.ErrorReadingSimCard,
        Enums.EventType.WatchdogTimerReset,
        Enums.EventType.BinaryLogsBufferOverrun,
        Enums.EventType.BinaryInputClosed,
        Enums.EventType.BinaryInputOpen,
        Enums.EventType.FlutterDetectionAutoDisable,
        Enums.EventType.FlutterDetectionAutoEnable,
        Enums.EventType.AnalogueLogsBufferoverrun,
        Enums.EventType.AnalogueThresholdCrossedRising,
        Enums.EventType.AnalogueThresholdCrossedFalling,
        Enums.EventType.AnalogueThresholdCrossed,
        Enums.EventType.RoutineAnalogueLevelReading,
        Enums.EventType.RoutineAnalogueLevelHostReading,
        Enums.EventType.LowLowThresholdCrossedRising,
        Enums.EventType.LowThresholdCrossedRising,
        Enums.EventType.HighThresholdCrossedRising,
        Enums.EventType.HighHighThresholdCrossedRising,
        Enums.EventType.LowLowThresholdCrossedFalling,
        Enums.EventType.LowThresholdCrossedFalling,
        Enums.EventType.HighThresholdCrossedFalling,
        Enums.EventType.HighHighThresholdCrossedFalling,
        Enums.EventType.InputCalibratedNewReading,
        Enums.EventType.CounterLogsBufferOverrun,
        Enums.EventType.CounterThresholdReached,
        Enums.EventType.CounterValueReceived,
        Enums.EventType.RtuLogBufferOverrun,
        Enums.EventType.DataLoggingBufferOverrun,
        Enums.EventType.Counter30MinuteDatalogging,
        Enums.EventType.OutputClosed,
        Enums.EventType.OutputOpened,
        Enums.EventType.PositionReceived,
        Enums.EventType.PositionRequested,
        Enums.EventType.LiveTrackingEnabled,
        Enums.EventType.LiveTrackingDisabled,
        Enums.EventType.LiveTrackingAutoDisabled,
        Enums.EventType.LiveTrackingAutoEnabled,
        Enums.EventType.ProtocolError,
        Enums.EventType.RtuTemperatureReport,
        Enums.EventType.RtuLate,
        Enums.EventType.ManualPollCallOK,
        Enums.EventType.ManualPollCallFailed,
        Enums.EventType.AutoPollCallOk,
        Enums.EventType.AutoPollCallFailed,
        Enums.EventType.ColdStart,
        Enums.EventType.RtuInitiatedCall,
        Enums.EventType.RtuBatteryOk,
        Enums.EventType.RtuBatteryLow,
        Enums.EventType.PowerFailed,
        Enums.EventType.PowerRestored,
        Enums.EventType.PodUndervoltage,
        Enums.EventType.PodOverVoltage,
        Enums.EventType.PodUndervoltageRestored,
        Enums.EventType.PodOverVoltageRestored,
        Enums.EventType.CommunicationRestored,
        Enums.EventType.CallFailed,
        Enums.EventType.DialoutFailedToConnect,
        Enums.EventType.TransientInstantaneousFault,
        Enums.EventType.TransientSEFFault,
        Enums.EventType.PermanentInstantaneousFault,
        Enums.EventType.PermanentSefFault,
        Enums.EventType.FaultReset,
        Enums.EventType.CommissioningCompleted,
        Enums.EventType.BinaryOutputRequestSent,
        Enums.EventType.BinaryOutputRequestFailed,
        Enums.EventType.SignatureLogBufferOverrun,
        Enums.EventType.SignatureLogReceived,
        Enums.EventType.BinaryOutputPatternSet,
        Enums.EventType.InsufficientEeprom,
        Enums.EventType.BitPatternSetReadMismatchOnPL1,
        Enums.EventType.SmsSendingFailed,
        Enums.EventType.BinaryInputStatusOpened,
        Enums.EventType.BinaryInputStatusClosed,
        Enums.EventType.SignalStrengthReceived,
        Enums.EventType.IgnitionOn,
        Enums.EventType.IgnitionOff,
        Enums.EventType.TcpConnectionDroppedUnexpectedly,
        Enums.EventType.InsufficientCredit,
        Enums.EventType.NoAnswerFromRtu,
        Enums.EventType.RtuBusy,
        Enums.EventType.NoCarrierConnectingToRtu,
        Enums.EventType.NoDialToneConnectingToRtu,
        Enums.EventType.NoChannelAvailable,
        Enums.EventType.PositionLogBufferOverrun,
        Enums.EventType.StartedMoving,
        Enums.EventType.StoppedMoving,
        Enums.EventType.ModemRebooted,
        Enums.EventType.PdpInvalidContext,
        Enums.EventType.PdpAccountInvalid,
        Enums.EventType.PdpShutdownFailure,
        Enums.EventType.PdpSetupCancelled,
        Enums.EventType.PdpTooManyActiveAccounts,
        Enums.EventType.PdpConflictWithHigherPriorityAccount,
        Enums.EventType.PdpTooManyActiveUsers,
        Enums.EventType.PdpNonExistentAccount,
        Enums.EventType.PdpStopAtUserRequest,
        Enums.EventType.PdpAuthenticationFailed,
        Enums.EventType.PdpBearerFailedConnect,
        Enums.EventType.PdpRemoteServerBusy,
        Enums.EventType.PdpRemoteServerRefused,
        Enums.EventType.PdpBearerBusy,
        Enums.EventType.PdpLineBusy,
        Enums.EventType.PdpUnknownError,
        Enums.EventType.PdpInvalidParameters,
        Enums.EventType.IPNoConnection,
        Enums.EventType.IPConnecting,
        Enums.EventType.IPConnected,
        Enums.EventType.IPConnectionBusy,
        Enums.EventType.IPOtherError,
        Enums.EventType.WarmStart,
        Enums.EventType.SmsSentToRtu,
        Enums.EventType.SMSResponseTimerElapsed,
        Enums.EventType.BinarySignatureLogReceived,
        Enums.EventType.RtuDiagnostic,
        Enums.EventType.CsdConnectionDroppedUnexpectedly,
        Enums.EventType.GprsConnectionNotSupported,
        Enums.EventType.SmsMessageReceived,
        Enums.EventType.ErrorWithModemSmsMemory,
        Enums.EventType.CallbackRequested,
        Enums.EventType.StxReceivedDuringIncomingMessage,
        Enums.EventType.CorruptIncomingMessageIgnored,
        Enums.EventType.UnspecifiedConnectionError,
        Enums.EventType.RingBackInitiated,
        Enums.EventType.RtuParked,
        Enums.EventType.RtuUnparked,
        Enums.EventType.SiteNameChanged,
        Enums.EventType.GroupChanged,
        Enums.EventType.SerialNumberChanged,
        Enums.EventType.UserData1Changed,
        Enums.EventType.UserData2Changed,
        Enums.EventType.UserData3Changed,
        Enums.EventType.UserData4Changed,
        Enums.EventType.UserData5Changed,
        Enums.EventType.UserData6Changed,
        Enums.EventType.LastMaintainedDateReset,
        Enums.EventType.CallCountersReset,
        Enums.EventType.AnalogueAverage,
        Enums.EventType.RingBackResponseTimerElapsed,
        Enums.EventType.BinaryInputClosedManualOverride,
        Enums.EventType.BinaryInputOpenedManualOverride,
        Enums.EventType.RtuBatteryOkManualOverride,
        Enums.EventType.ConnectionTimeout,
        Enums.EventType.NoDataReceived,
        Enums.EventType.ConnectionAuthenticationError,
        Enums.EventType.EstablishConnectionInterrupted,
        Enums.EventType.ConnectionToDeviceLost,
        Enums.EventType.DisconnectedForCallBack,
        Enums.EventType.DisconnectedForNewAlarm,
        Enums.EventType.PdpActivationFailure,
        Enums.EventType.IPConnectionFailure,
        Enums.EventType.EepromBufferOverrun,
        Enums.EventType.AnalogueInputInvalid,
        Enums.EventType.AnalogueInputValid,
        Enums.EventType.IODeviceOffline,
        Enums.EventType.IODeviceOnline,
        Enums.EventType.ScheduledBinaryOutputQueueFailed,
        Enums.EventType.CommsSettingsSentToDevice,
        Enums.EventType.AggressiveCallInModeEnabled,
        Enums.EventType.AggressiveCallInModeDisabled,
        Enums.EventType.AggressiveCallInModeManuallyDisabled,
        Enums.EventType.SMSNotificationsDisabled,
        Enums.EventType.SMTPNotificationsDisabled,
        Enums.EventType.SMSNotificationsEnabled,
        Enums.EventType.SMTPNotificationsEnabled,
        Enums.EventType.SMSNotificationsLimitReached,
        Enums.EventType.EmailReceivedFromUnit,
        Enums.EventType.ModemFailedtoShutdown,
        Enums.EventType.ColdstartCallDelayed,
        Enums.EventType.RtuOffline,
        Enums.EventType.RtuOnline,
        Enums.EventType.Connected,
        Enums.EventType.Disconnected,
        Enums.EventType.BufferOverflow,
        Enums.EventType.DeviceTrouble,
        Enums.EventType.Connecting,
        Enums.EventType.CounterDeltaValue,
        Enums.EventType.PollRequested,
        Enums.EventType.BinaryOutputRequestCompleted,
        Enums.EventType.PollSuccessful,
        Enums.EventType.PollFailed,
        Enums.EventType.Disconnecting,
        Enums.EventType.ConnectionReset,
        Enums.EventType.ConnectionActivityTimeout,
        Enums.EventType.UnexpectedSerialNumber,
        Enums.EventType.BinaryOutputCommandTimeout,
        Enums.EventType.BinaryOutputCommandRejected,
        Enums.EventType.DeviceHealthy,
        Enums.EventType.DisturbanceXMLParsingFailed,
        Enums.EventType.DeadbandCrossed,
        Enums.EventType.AnalogueOutputLevelReading,
        Enums.EventType.AnalogueOutputCommandTimeout,
        Enums.EventType.AnalogueOutputCommandRejected,
        Enums.EventType.AnalogueOutputRequestSent,
        Enums.EventType.AnalogueOutputRequestFailed,
        Enums.EventType.AnalogueOutputRequestCompleted,
        Enums.EventType.AnalogueOutputCommandSuccessful,
        Enums.EventType.BinaryOutputCommandSuccessful,
        Enums.EventType.ProcessingFile,
        Enums.EventType.FileImportSuccessful,
        Enums.EventType.FileImportFailed,
        Enums.EventType.InvalidDeviceIdentity,
        Enums.EventType.WaitingForValidSerialNumber,
        Enums.EventType.SerialNumberValidated,
        Enums.EventType.DeviceConfigFileImported,
        Enums.EventType.DeviceConfigFileImportFailed,
        Enums.EventType.StartupCompleted,
        Enums.EventType.StartupFailed,
        Enums.EventType.NeedTime,
        Enums.EventType.SmsReceivedFromRtu,
        Enums.EventType.RtuCreated,
        Enums.EventType.RtuAclResendRequested,
        Enums.EventType.RtuAclCleared,
        Enums.EventType.RtuAclEntryAdded,
        Enums.EventType.RtuAclEntryRemoved,
        Enums.EventType.RtuAclEntryStored,
        Enums.EventType.RingFromAuthorisedPhoneNumber,
        Enums.EventType.RingFromUnauthorisedPhoneNumber,
        Enums.EventType.SmsFromAuthorisedPhoneNumber,
        Enums.EventType.SmsFromUnauthorisedPhoneNumber,
        Enums.EventType.RtuAclEntryCleared,
        Enums.EventType.NoValidConfigFound,
        Enums.EventType.RtuLocked,
        Enums.EventType.RtuUnlocked,
        Enums.EventType.RtuNoteAdded,
        Enums.EventType.RtuNoteEdited,
        Enums.EventType.RtuNoteDeleted,
        Enums.EventType.RingBackRequested,
        Enums.EventType.RtuFirmwareAssigned,
        Enums.EventType.RtuFirmwareUploadStarted,
        Enums.EventType.RtuFirmwareUploadSucceeded,
        Enums.EventType.RtuFirmwareUploadFailed,
        Enums.EventType.RtuFirmwareUpgradeFailed,
        Enums.EventType.RtuFirmwareUpgradeSucceeded,
        Enums.EventType.RtuFirmwareUpgradeConfigurationFailed,
        Enums.EventType.RtuCertificateCreated,
        Enums.EventType.RtuCertificateDeleted,
        Enums.EventType.InvalidRtuCertificate,
        Enums.EventType.RtuCertificateUploaded,
        Enums.EventType.AnalogueOutputLevelHostReading,
        Enums.EventType.AnmResultsFile,
        Enums.EventType.DisconnectedLocalClose,
        Enums.EventType.OutputStatusClosed,
        Enums.EventType.OutputStatusOpened,
        Enums.EventType.LogicInitialised,
        Enums.EventType.LogicRunCompletedControlModuleDisabled,
        Enums.EventType.LogicRunCompletedRemoteControlNotAvailable,
        Enums.EventType.LogicRunCompletedNoViolations,
        Enums.EventType.LogicRunCompletedLatched,
        Enums.EventType.LogicRunCompletedSetpointsCalculated,
        Enums.EventType.LogicRunCompletedSetpointsDispatched,
        Enums.EventType.LogicRunCompletedControlRetry,
        Enums.EventType.LogicError1MinimumDatasetNotAvailable,
        Enums.EventType.LogicError2UnexpectedConfiguration,
        Enums.EventType.LogicError3StateEstimationFailure,
        Enums.EventType.LogicError4StateEstimationMismatch,
        Enums.EventType.LogicError5NoSolutionFound,
        Enums.EventType.LogicError6SetpointControlFailure,
        Enums.EventType.LogicFailed,
        Enums.EventType.LogicGracefulShutdown,
        Enums.EventType.StringValueReceived,
        Enums.EventType.StringValueChanged,
        Enums.EventType.PostFirmwareUpgradeExtendedConfigurationFailed,
        Enums.EventType.RtuFailedToSendSms,
        Enums.EventType.LocalFirmwareUpdateStarted,
        Enums.EventType.LocalFirmwareUpdateTimedOut,
        Enums.EventType.RemoteFirmwareCheckStarted,
        Enums.EventType.RemoteFirmwareCheckTimedOut,
        Enums.EventType.RemoteFirmwareFileUnavailable,
        Enums.EventType.RemoteFirmwareSameAsCurrentlyRunning,
        Enums.EventType.FirmwareFileInvalid,
        Enums.EventType.NewFirmwareLoadingOrVerificationFailed,
        Enums.EventType.NewFirmwareLoadingOrVerificationComplete,
        Enums.EventType.NewFirmwareFirstBootTemporary,
        Enums.EventType.NewFirmwareTransitionComplete,
        Enums.EventType.NXBFieldNotSupported,
        Enums.EventType.TemplateSyncEnabled,
        Enums.EventType.TemplateSyncDisabled,
        Enums.EventType.TemplateSyncCompleted,
        Enums.EventType.TemplateSyncFailed,
        Enums.EventType.BrownoutReset,
        Enums.EventType.PQEvent,
        Enums.EventType.PQIncompleteEvent,
        Enums.EventType.DeviceApiLate,
        Enums.EventType.DeviceApiCommsRestored,
        Enums.EventType.PQPendingEvent,
        Enums.EventType.FirmwareVersionChanged,
        Enums.EventType.RtuConfigurationDownloadedByRtu,
        Enums.EventType.RtuConfigurationUploadedByRtu,
        Enums.EventType.RtuConfigurationImportedFromRtu,
        Enums.EventType.RtuConfigurationFailedImport,
        Enums.EventType.RtuConfigurationFailedExport,
        Enums.EventType.RtuConfigurationDownloadRequestedByRtu,
        Enums.EventType.RtuConfigurationNoChangesFoundForDownloadByRtu,
        Enums.EventType.RtuConfigurationMetadataRequestedByRtu,
        Enums.EventType.AnalogueRoutineDataloggingFailed,
        Enums.EventType.AnalogueRoutineDataloggingRestored,
        Enums.EventType.FileImportError,
        Enums.EventType.FileImportWarning,
        Enums.EventType.PushNotificationsDisabled,
        Enums.EventType.PushNotificationsEnabled,
        Enums.EventType.DecommissioningCompleted,
        Enums.EventType.DoubleBitInputIntermediate,
        Enums.EventType.DoubleBitInputOpen,
        Enums.EventType.DoubleBitInputClosed,
        Enums.EventType.DoubleBitInputIndeterminate,
    ];

    public static readonly NexusDiagnosticEventLogTypes = [
        Enums.EventType.OtherUnknown,
        Enums.EventType.DialToConnectTimerElapsed,
        Enums.EventType.PacketWaitTimerElapsed,
        Enums.EventType.CatchAllTimerElapsed,
        Enums.EventType.WatchdogTimerReset,
        Enums.EventType.ProtocolError,
        Enums.EventType.DialoutFailedToConnect
    ];

    public static readonly SystemLogTypes = [
        Enums.SystemLogType.Undefined,
        Enums.SystemLogType.CSMStarted,
        Enums.SystemLogType.CSMFailed,
        Enums.SystemLogType.CSMAdded,
        Enums.SystemLogType.CSMDeleted,
        Enums.SystemLogType.CSMSettingsChanged,
        Enums.SystemLogType.CSMLineAdded,
        Enums.SystemLogType.CSMLineDeleted,
        Enums.SystemLogType.CSMLineSettingsChanged,
        Enums.SystemLogType.CSMShutdown,
        Enums.SystemLogType.TMStarted,
        Enums.SystemLogType.TMShutdown,
        Enums.SystemLogType.TMAdded,
        Enums.SystemLogType.TMDeleted,
        Enums.SystemLogType.TMTaskDeleted,
        Enums.SystemLogType.TMSettingsChanged,
        Enums.SystemLogType.TMTaskSettingsChanged,
        Enums.SystemLogType.CallfromUnknownRTU,
        Enums.SystemLogType.RTUSerialNoMismatch,
        Enums.SystemLogType.NewUserAdded,
        Enums.SystemLogType.UserAccountToAddressBookEntryLinkFailed,
        Enums.SystemLogType.UserDeleted,
        Enums.SystemLogType.NewRTUCreated,
        Enums.SystemLogType.RTUDeleted,
        Enums.SystemLogType.ProtocolError,
        Enums.SystemLogType.LineFailed,
        Enums.SystemLogType.LineRestored,
        Enums.SystemLogType.DatabaseFailed,
        Enums.SystemLogType.DatabaseRestored,
        Enums.SystemLogType.CSMStartupFailed,
        Enums.SystemLogType.SendConfigPacketFailed,
        Enums.SystemLogType.ScadaInterfaceStarted,
        Enums.SystemLogType.ScadaInterfaceStopped,
        Enums.SystemLogType.ScadaInterfaceFailed,
        Enums.SystemLogType.ScadaLinkOK,
        Enums.SystemLogType.ScadaLinkDown,
        Enums.SystemLogType.TaskLate,
        Enums.SystemLogType.FailedCallfromUnknownCallerLineID,
        Enums.SystemLogType.ConfigQueueEntryAdded,
        Enums.SystemLogType.ConfigQueueEntryCancelled,
        Enums.SystemLogType.SMSmessageFromUnknownNumber,
        Enums.SystemLogType.NoDataReceivedOnLine,
        Enums.SystemLogType.TaskManagerTaskExecutionFailure,
        Enums.SystemLogType.TaskManagerFailed,
        Enums.SystemLogType.NewCustomerCreated,
        Enums.SystemLogType.CustomerDeleted,
        Enums.SystemLogType.AssignedUserGroupChanged,
        Enums.SystemLogType.SMSCommandReceived,
        Enums.SystemLogType.ModemReset,
        Enums.SystemLogType.ModemFailedToReset,
        Enums.SystemLogType.MessagingLimitExceeded,
        Enums.SystemLogType.MessagingDisabled,
        Enums.SystemLogType.MessagingAutoDisabled,
        Enums.SystemLogType.MessagingEnabled,
        Enums.SystemLogType.MessagingSettingsUpdated,
        Enums.SystemLogType.MessagingLimitsUpdated,
        Enums.SystemLogType.SMSNotificationsDisabled,
        Enums.SystemLogType.SMTPNotificationsDisabled,
        Enums.SystemLogType.SMSNotificationsEnabled,
        Enums.SystemLogType.SMTPNotificationsEnabled,
        Enums.SystemLogType.CallCounterThresholdExceeded,
        Enums.SystemLogType.SMSNotificationsLimitReached,
        Enums.SystemLogType.EmailFromUnknownSourceReceived,
        Enums.SystemLogType.CustomerSettingsChanged,
        Enums.SystemLogType.ArchivedEvents,
        Enums.SystemLogType.MailboxPollFailure,
        Enums.SystemLogType.DisturbanceProcessingFailed,
        Enums.SystemLogType.CsmVersionChanged,
        Enums.SystemLogType.TmVersionChanged,
        Enums.SystemLogType.ScadaVersionChanged,
        Enums.SystemLogType.UserPasswordChanged,
        Enums.SystemLogType.ForcedPasswordExpiry,
        Enums.SystemLogType.CredentialAdded,
        Enums.SystemLogType.CredentialUpdated,
        Enums.SystemLogType.CredentialDeleted,
        Enums.SystemLogType.UserImpersonationStarted,
        Enums.SystemLogType.UserVerificationCodeSent,
        Enums.SystemLogType.UserVerified,
        Enums.SystemLogType.UserRejected,
        Enums.SystemLogType.CsmHostMachineChanged,
        Enums.SystemLogType.TmHostMachineChanged,
        Enums.SystemLogType.ScadaHostMachineChanged,
        Enums.SystemLogType.RTULocked,
        Enums.SystemLogType.RTUUnlocked,
        Enums.SystemLogType.UserAccessChanged,
        Enums.SystemLogType.RtuFirmwareUploaded,
        Enums.SystemLogType.RtuFirmwareDeleted,
        Enums.SystemLogType.NetworkAdded,
        Enums.SystemLogType.NetworkSnapshotCreated,
        Enums.SystemLogType.NetworkDeleted,
        Enums.SystemLogType.NetworkSnapshotDeleted,
        Enums.SystemLogType.SystemCertificateUploaded,
        Enums.SystemLogType.SystemCertificateDeleted,
        Enums.SystemLogType.SystemCertificateCreated,
        Enums.SystemLogType.RtuCertificateCreated,
        Enums.SystemLogType.RtuCertificateDeleted,
        Enums.SystemLogType.UnknownRTUAddress,
        Enums.SystemLogType.RtuCertificateUploaded,
        Enums.SystemLogType.MessageBrokerRestored,
        Enums.SystemLogType.MessageBrokerFailed,
        Enums.SystemLogType.RtuRestored,
        Enums.SystemLogType.NoDataReceivedOnScadaChannel,
        Enums.SystemLogType.UserMoved,
        Enums.SystemLogType.CustomerNoteAdded,
        Enums.SystemLogType.CustomerNoteEdited,
        Enums.SystemLogType.CustomerNoteDeleted,
        Enums.SystemLogType.PushNotificationsDisabled,
        Enums.SystemLogType.PushNotificationsEnabled,
        Enums.SystemLogType.SystemHealthAlarm
    ];

    public static NetworkNodeTypes = [
        Enums.NetworkNodeType.Rtu,
        Enums.NetworkNodeType.Junction,
        Enums.NetworkNodeType.LinkedNetwork,
        Enums.NetworkNodeType.Point,
        Enums.NetworkNodeType.Bus,
        Enums.NetworkNodeType.Infeeder,
        Enums.NetworkNodeType.Generator,
        Enums.NetworkNodeType.Load
    ];

    public static NetworkNodeDisplayTypes = [
        Enums.NetworkNodeDisplayType.Icon,
        Enums.NetworkNodeDisplayType.Shape
    ];

    public static NetworkEdgeTypes = [
        Enums.NetworkEdgeType.Line,
        Enums.NetworkEdgeType.Switch,
        Enums.NetworkEdgeType.Transformer,
        Enums.NetworkEdgeType.Cable
    ];

    public static NetworkGroupTypes = [
        Enums.NetworkGroupType.Group,
        Enums.NetworkGroupType.Feeder
    ];

    public static SystemServiceTypes: Enums.SystemServiceType[] = [
        Enums.SystemServiceType.Csm,
        Enums.SystemServiceType.TaskManager,
        Enums.SystemServiceType.ScadaInterface
    ];

    public static LogEventLevels: Enums.LogEventLevel[] = [
        Enums.LogEventLevel.Verbose,
        Enums.LogEventLevel.Debug,
        Enums.LogEventLevel.Info,
        Enums.LogEventLevel.Warning,
        Enums.LogEventLevel.Error,
        Enums.LogEventLevel.Fatal
    ];

    public static NetworkFaultCategories: Enums.NetworkFaultCategory[] = [
        Enums.NetworkFaultCategory.Indeterminate,
        Enums.NetworkFaultCategory.PostFault,
        Enums.NetworkFaultCategory.PreFault
    ];

    public static hasFlag(value: number, flag: number): boolean {
        return flag === (value & flag);
    }

    public static RtuBulkImportFormats: Enums.RtuBulkImportFormat[] = [
        Enums.RtuBulkImportFormat.VisnetEnrollmentPackage
    ];

    /**
     * Converts Quality flags into a human readable string.
     */
     public static getQualityDescription(quality: Enums.Quality, separator = ", ", includeNormal = false) {
         if (quality === Enums.Quality.Normal) {
             return includeNormal ? "Normal" : "";
         }

         const flags = [];

         if (this.hasFlag(quality, Enums.Quality.Offline)) {
             flags.push("Offline");
         }
         if (this.hasFlag(quality, Enums.Quality.Restart)) {
            flags.push("Restart");
         }
         if (this.hasFlag(quality, Enums.Quality.CommsLost)) {
             flags.push("Comms Lost");
         }
         if (this.hasFlag(quality, Enums.Quality.RemoteForced)) {
             flags.push("Remote Forced");
         }
         if (this.hasFlag(quality, Enums.Quality.LocalForced)) {
             flags.push("Local Forced");
         }
         if (this.hasFlag(quality, Enums.Quality.OverRange)) {
             flags.push("Over Range");
         }
         if (this.hasFlag(quality, Enums.Quality.Suspect)) {
             flags.push("Suspect");
         }
         if (this.hasFlag(quality, Enums.Quality.Invalid)) {
             flags.push("Invalid");
         }

         return flags.length > 0 ? flags.join(separator) : "Unknown";
    }

    /**
     * Converts an System Backup type to human readable string.
     */
    public static getSystemBackupTypeString(systemBackupType: Enums.SystemBackupType) {
        switch (systemBackupType) {
            case Enums.SystemBackupType.Full:
                return "Full";

            case Enums.SystemBackupType.Partial:
                return "Partial";

            default:
                return "Unknown";
        }
    }

    /**
     * Converts an event type into a human readable string.
     * See case 19700 to auto-generate
     * @param eventType EventType
     */
    public static getEventLogTypeDescription(eventType: Enums.EventType) {
        switch (eventType) {

            case Enums.EventType.OtherUnknown:
                return "Unknown Event";

            case Enums.EventType.CommsDeviceFailedtoRespond:
                return "Comms Device: Failed to Respond";

            case Enums.EventType.NetworkUnavailable:
                return "Network Unavailable";

            case Enums.EventType.HostBusy:
                return "Host Busy";

            case Enums.EventType.NoCarrierOnCommsdevice:
                return "No Carrier on Comms device";

            case Enums.EventType.DialToConnectTimerElapsed:
                return "Dial-to-Connect Timer Elapsed";

            case Enums.EventType.PacketWaitTimerElapsed:
                return "Packet Wait Timer Elapsed";

            case Enums.EventType.CatchAllTimerElapsed:
                return "Catch All Timer Elapsed";

            case Enums.EventType.ErrorReadingSimCard:
                return "Error Reading SIM Card";

            case Enums.EventType.WatchdogTimerReset:
                return "Watchdog Timer Reset";

            case Enums.EventType.BinaryLogsBufferOverrun:
                return "Binary Logs Buffer over-run";

            case Enums.EventType.BinaryInputClosed:
                return "Binary Input Closed";

            case Enums.EventType.BinaryInputOpen:
                return "Binary Input Open";

            case Enums.EventType.FlutterDetectionAutoDisable:
                return "Flutter Detection Auto-Disable";

            case Enums.EventType.FlutterDetectionAutoEnable:
                return "Flutter Detection Auto-Enable";

            case Enums.EventType.AnalogueLogsBufferoverrun:
                return "Analogue Logs Buffer over-run";

            case Enums.EventType.AnalogueThresholdCrossedRising:
                return "Analogue Threshold Crossed Rising";

            case Enums.EventType.AnalogueThresholdCrossedFalling:
                return "Analogue Threshold Crossed Falling";

            case Enums.EventType.AnalogueThresholdCrossed:
                return "Analogue Threshold Crossed";

            case Enums.EventType.RoutineAnalogueLevelReading:
                return "Routine Analogue Level Reading";

            case Enums.EventType.RoutineAnalogueLevelHostReading:
                return "Routine Analogue Level Host Reading";

            case Enums.EventType.LowLowThresholdCrossedRising:
                return "LowLow Threshold Crossed Rising";

            case Enums.EventType.LowThresholdCrossedRising:
                return "Low Threshold Crossed Rising";

            case Enums.EventType.HighThresholdCrossedRising:
                return "High Threshold Crossed Rising";

            case Enums.EventType.HighHighThresholdCrossedRising:
                return "HighHigh Threshold Crossed Rising";

            case Enums.EventType.LowLowThresholdCrossedFalling:
                return "LowLow Threshold Crossed Falling";

            case Enums.EventType.LowThresholdCrossedFalling:
                return "Low Threshold Crossed Falling";

            case Enums.EventType.HighThresholdCrossedFalling:
                return "High Threshold Crossed Falling";

            case Enums.EventType.HighHighThresholdCrossedFalling:
                return "HighHigh Threshold Crossed Falling";

            case Enums.EventType.InputCalibratedNewReading:
                return "Input Calibrated, New reading";

            case Enums.EventType.CounterLogsBufferOverrun:
                return "Counter Logs buffer over-run";

            case Enums.EventType.CounterThresholdReached:
                return "Counter Threshold Reached";

            case Enums.EventType.CounterValueReceived:
                return "Counter Value Received";

            case Enums.EventType.RtuLogBufferOverrun:
                return "RTU Log Buffer over-run";

            case Enums.EventType.DataLoggingBufferOverrun:
                return "Datalogging Buffer over-run";

            case Enums.EventType.Counter30MinuteDatalogging:
                return "30 minute counter input datalogging";

            case Enums.EventType.OutputClosed:
                return "Output Closed";

            case Enums.EventType.OutputOpened:
                return "Output Opened";

            case Enums.EventType.PositionReceived:
                return "Position Received";

            case Enums.EventType.PositionRequested:
                return "Position Requested";

            case Enums.EventType.LiveTrackingEnabled:
                return "Live Tracking Enabled";

            case Enums.EventType.LiveTrackingDisabled:
                return "Live Tracking Disabled";

            case Enums.EventType.LiveTrackingAutoDisabled:
                return "Live Tracking Auto-Disabled";

            case Enums.EventType.LiveTrackingAutoEnabled:
                return "Live Tracking Auto-Enabled";

            case Enums.EventType.ProtocolError:
                return "Protocol Error";

            case Enums.EventType.RtuTemperatureReport:
                return "RTU Temperature Report";

            case Enums.EventType.RtuLate:
                return "Comms Failed / RTU Late";

            case Enums.EventType.ManualPollCallOK:
                return "Manual Poll Call - OK";

            case Enums.EventType.ManualPollCallFailed:
                return "Manual Poll Call - Failed";

            case Enums.EventType.AutoPollCallOk:
                return "Auto Poll Call - OK";

            case Enums.EventType.AutoPollCallFailed:
                return "Auto Poll Call - Failed";

            case Enums.EventType.ColdStart:
                return "Cold Start";

            case Enums.EventType.RtuInitiatedCall:
                return "RTU Initiated Call";

            case Enums.EventType.RtuBatteryOk:
                return "RTU Battery OK";

            case Enums.EventType.RtuBatteryLow:
                return "RTU Battery Low";

            case Enums.EventType.PowerFailed:
                return "RTU Power Failed";

            case Enums.EventType.PowerRestored:
                return "RTU Power Restored";

            case Enums.EventType.PodUndervoltage:
                return "POD Under-voltage";

            case Enums.EventType.PodOverVoltage:
                return "POD Over-Voltage";

            case Enums.EventType.PodUndervoltageRestored:
                return "POD Under-voltage Restored";

            case Enums.EventType.PodOverVoltageRestored:
                return "POD Over-Voltage Restored";

            case Enums.EventType.CommunicationRestored:
                return "Comms Restored";

            case Enums.EventType.CallFailed:
                return "Call Failed";

            case Enums.EventType.DialoutFailedToConnect:
                return "Dial out Failed to connect";

            case Enums.EventType.TransientInstantaneousFault:
                return "Transient Instantaneous Fault";

            case Enums.EventType.TransientSEFFault:
                return "Transient SEF Fault";

            case Enums.EventType.PermanentInstantaneousFault:
                return "Permanent Instantaneous Fault";

            case Enums.EventType.PermanentSefFault:
                return "Permanent SEF Fault";

            case Enums.EventType.FaultReset:
                return "Fault Reset";

            case Enums.EventType.CommissioningCompleted:
                return "Commissioning Completed";

            case Enums.EventType.BinaryOutputRequestSent:
                return "Binary Output Request Sent";

            case Enums.EventType.BinaryOutputRequestFailed:
                return "Binary Output Request Failed";

            case Enums.EventType.SignatureLogBufferOverrun:
                return "Signature Log Buffer Over-run";

            case Enums.EventType.SignatureLogReceived:
                return "Signature Log Received";

            case Enums.EventType.BinaryOutputPatternSet:
                return "Binary Output Pattern Set";

            case Enums.EventType.InsufficientEeprom:
                return "Insufficient EEPROM";

            case Enums.EventType.BitPatternSetReadMismatchOnPL1:
                return "Bit Pattern Set/Read Mismatch on PL1";

            case Enums.EventType.SmsSendingFailed:
                return "SMS Sending Failed";

            case Enums.EventType.BinaryInputStatusOpened:
                return "Binary Input Status opened";

            case Enums.EventType.BinaryInputStatusClosed:
                return "Binary Input Status closed";

            case Enums.EventType.SignalStrengthReceived:
                return "Signal Strength Received";

            case Enums.EventType.IgnitionOn:
                return "Ignition On";

            case Enums.EventType.IgnitionOff:
                return "Ignition Off";

            case Enums.EventType.TcpConnectionDroppedUnexpectedly:
                return "TCP/IP connection dropped unexpectedly";

            case Enums.EventType.InsufficientCredit:
                return "Insufficient Credit";

            case Enums.EventType.NoAnswerFromRtu:
                return "No Answer From RTU";

            case Enums.EventType.RtuBusy:
                return "RTU Busy";

            case Enums.EventType.NoCarrierConnectingToRtu:
                return "No Carrier Connecting To RTU";

            case Enums.EventType.NoDialToneConnectingToRtu:
                return "No Dial Tone Connecting To RTU";

            case Enums.EventType.NoChannelAvailable:
                return "No Channel Available";

            case Enums.EventType.PositionLogBufferOverrun:
                return "Position Log Buffer Overrun";

            case Enums.EventType.StartedMoving:
                return "Started Moving";

            case Enums.EventType.StoppedMoving:
                return "Stopped Moving";

            case Enums.EventType.ModemRebooted:
                return "Modem Rebooted";

            case Enums.EventType.PdpInvalidContext:
                return "PDP - Invalid Context";

            case Enums.EventType.PdpAccountInvalid:
                return "PDP - Account Invalid";

            case Enums.EventType.PdpShutdownFailure:
                return "PDP - Shutdown Failure";

            case Enums.EventType.PdpSetupCancelled:
                return "PDP - Setup Cancelled";

            case Enums.EventType.PdpTooManyActiveAccounts:
                return "PDP - Too Many Active Accounts";

            case Enums.EventType.PdpConflictWithHigherPriorityAccount:
                return "PDP - Conflict with higher priority account";

            case Enums.EventType.PdpTooManyActiveUsers:
                return "PDP - Too Many Active Users";

            case Enums.EventType.PdpNonExistentAccount:
                return "PDP - Non Existent Account";

            case Enums.EventType.PdpStopAtUserRequest:
                return "PDP - Stop at User Request";

            case Enums.EventType.PdpAuthenticationFailed:
                return "PDP - Authentication Failed";

            case Enums.EventType.PdpBearerFailedConnect:
                return "PDP - Bearer Failed Connect";

            case Enums.EventType.PdpRemoteServerBusy:
                return "PDP - Remote Server Busy";

            case Enums.EventType.PdpRemoteServerRefused:
                return "PDP - Remote Server Refused";

            case Enums.EventType.PdpBearerBusy:
                return "PDP - Bearer Busy";

            case Enums.EventType.PdpLineBusy:
                return "PDP - Line Busy";

            case Enums.EventType.PdpUnknownError:
                return "PDP - Unknown Error";

            case Enums.EventType.PdpInvalidParameters:
                return "PDP - Invalid Parameters";

            case Enums.EventType.IPNoConnection:
                return "IP - No Connection";

            case Enums.EventType.IPConnecting:
                return "IP - Connecting";

            case Enums.EventType.IPConnected:
                return "IP - Connected";

            case Enums.EventType.IPConnectionBusy:
                return "IP - Connection Busy";

            case Enums.EventType.IPOtherError:
                return "IP - Other Error";

            case Enums.EventType.WarmStart:
                return "Warm Start";

            case Enums.EventType.SmsSentToRtu:
                return "SMS Sent to RTU";

            case Enums.EventType.SMSResponseTimerElapsed:
                return "SMS Response Timer Elapsed";

            case Enums.EventType.BinarySignatureLogReceived:
                return "Binary Signature Log Received";

            case Enums.EventType.RtuDiagnostic:
                return "RTU Diagnostic Event";

            case Enums.EventType.CsdConnectionDroppedUnexpectedly:
                return "CSD Connection Dropped Unexpectedly";

            case Enums.EventType.GprsConnectionNotSupported:
                return "GPRS Connection Not Supported";

            case Enums.EventType.SmsMessageReceived:
                return "SMS Message Received";

            case Enums.EventType.ErrorWithModemSmsMemory:
                return "Error With Modem SMS Memory";

            case Enums.EventType.CallbackRequested:
                return "Callback Requested";

            case Enums.EventType.StxReceivedDuringIncomingMessage:
                return "STX Received During Incoming Message";

            case Enums.EventType.CorruptIncomingMessageIgnored:
                return "Corrupt Incoming Message Ignored";

            case Enums.EventType.UnspecifiedConnectionError:
                return "Unspecified Connection Error";

            case Enums.EventType.RingBackInitiated:
                return "Ring Back Initiated";

            case Enums.EventType.RtuParked:
                return "RTU Parked";

            case Enums.EventType.RtuUnparked:
                return "RTU Un-parked";

            case Enums.EventType.SiteNameChanged:
                return "Site Name Changed";

            case Enums.EventType.GroupChanged:
                return "Group Changed";

            case Enums.EventType.SerialNumberChanged:
                return "Serial Number Changed";

            case Enums.EventType.UserData1Changed:
                return "User Data 1 Changed";

            case Enums.EventType.UserData2Changed:
                return "User Data 2 Changed";

            case Enums.EventType.UserData3Changed:
                return "User Data 3 Changed";

            case Enums.EventType.UserData4Changed:
                return "User Data 4 Changed";

            case Enums.EventType.UserData5Changed:
                return "User Data 5 Changed";

            case Enums.EventType.UserData6Changed:
                return "User Data 6 Changed";

            case Enums.EventType.LastMaintainedDateReset:
                return "Last Maintained Date Reset";

            case Enums.EventType.CallCountersReset:
                return "Call Counters Reset";

            case Enums.EventType.AnalogueAverage:
                return "Analogue Average";

            case Enums.EventType.RingBackResponseTimerElapsed:
                return "Ring Back Response Timer elapsed";

            case Enums.EventType.BinaryInputClosedManualOverride:
                return "Binary Input Closed (Manual Override)";

            case Enums.EventType.BinaryInputOpenedManualOverride:
                return "Binary Input Open (Manual Override)";

            case Enums.EventType.RtuBatteryOkManualOverride:
                return "RTU Battery OK (Manual Override)";

            case Enums.EventType.ConnectionTimeout:
                return "Connection Timeout";

            case Enums.EventType.NoDataReceived:
                return "No Data Received";

            case Enums.EventType.ConnectionAuthenticationError:
                return "Connection Authentication Error";

            case Enums.EventType.EstablishConnectionInterrupted:
                return "Establish Connection Interrupted";

            case Enums.EventType.ConnectionToDeviceLost:
                return "Connection to Device Lost";

            case Enums.EventType.DisconnectedForCallBack:
                return "Disconnected for Call Back";

            case Enums.EventType.DisconnectedForNewAlarm:
                return "Disconnected for New Alarm";

            case Enums.EventType.PdpActivationFailure:
                return "PDP - Activation Failure";

            case Enums.EventType.IPConnectionFailure:
                return "IP - Connection Failure";

            case Enums.EventType.EepromBufferOverrun:
                return "EEPROM buffer overrun";

            case Enums.EventType.AnalogueInputInvalid:
                return "Analogue Input Invalid";

            case Enums.EventType.AnalogueInputValid:
                return "Analogue Input Valid";

            case Enums.EventType.IODeviceOffline:
                return "IO Device Offline";

            case Enums.EventType.IODeviceOnline:
                return "IO Device Online";

            case Enums.EventType.ScheduledBinaryOutputQueueFailed:
                return "Scheduled Binary Output Queue Failed";

            case Enums.EventType.CommsSettingsSentToDevice:
                return "Comms Settings Sent to Device";

            case Enums.EventType.AggressiveCallInModeEnabled:
                return "Aggressive Call-in Mode Enabled";

            case Enums.EventType.AggressiveCallInModeDisabled:
                return "Aggressive Call-in Mode Disabled";

            case Enums.EventType.AggressiveCallInModeManuallyDisabled:
                return "Aggressive Call-in Mode Manually Disabled";

            case Enums.EventType.SMSNotificationsDisabled:
                return "SMS Notifications have been disabled";

            case Enums.EventType.SMTPNotificationsDisabled:
                return "SMTP Notifications have been disabled";

            case Enums.EventType.SMSNotificationsEnabled:
                return "SMS Notifications have been enabled";

            case Enums.EventType.SMTPNotificationsEnabled:
                return "SMTP Notifications have been enabled";

            case Enums.EventType.SMSNotificationsLimitReached:
                return "SMS Notifications limit has been reached";

            case Enums.EventType.EmailReceivedFromUnit:
                return "Email Received From Unit";

            case Enums.EventType.ModemFailedtoShutdown:
                return "Modem Failed to Shutdown";

            case Enums.EventType.ColdstartCallDelayed:
                return "Coldstart Call Delayed";

            case Enums.EventType.RtuOffline:
                return "RTU Offline";

            case Enums.EventType.RtuOnline:
                return "RTU Online";

            case Enums.EventType.Connected:
                return "Connected";

            case Enums.EventType.Disconnected:
                return "Disconnected";

            case Enums.EventType.BufferOverflow:
                return "Buffer Overflow";

            case Enums.EventType.DeviceTrouble:
                return "Device Trouble";

            case Enums.EventType.Connecting:
                return "Connecting";

            case Enums.EventType.CounterDeltaValue:
                return "Counter Delta value";

            case Enums.EventType.PollRequested:
                return "Poll Requested";

            case Enums.EventType.BinaryOutputRequestCompleted:
                return "Binary Output Request Completed";

            case Enums.EventType.PollSuccessful:
                return "Poll Successful";

            case Enums.EventType.PollFailed:
                return "Poll Failed";

            case Enums.EventType.Disconnecting:
                return "Disconnecting";

            case Enums.EventType.ConnectionReset:
                return "Connection Reset";

            case Enums.EventType.ConnectionActivityTimeout:
                return "Connection Activity Timeout";

            case Enums.EventType.UnexpectedSerialNumber:
                return "Unexpected Serial Number";

            case Enums.EventType.BinaryOutputCommandTimeout:
                return "Binary Output Command Timeout";

            case Enums.EventType.BinaryOutputCommandRejected:
                return "Binary Output Command Rejected";

            case Enums.EventType.DeviceHealthy:
                return "Device Healthy";

            case Enums.EventType.DisturbanceXMLParsingFailed:
                return "Disturbance XML Parsing Failed";

            case Enums.EventType.DeadbandCrossed:
                return "Analogue Deadband Crossed";

            case Enums.EventType.AnalogueOutputLevelReading:
                return "Analogue Output Level Reading";

            case Enums.EventType.AnalogueOutputCommandTimeout:
                return "Analogue Output Command Timeout";

            case Enums.EventType.AnalogueOutputCommandRejected:
                return "Analogue Output Command Rejected";

            case Enums.EventType.AnalogueOutputRequestSent:
                return "Analogue Output Request Sent";

            case Enums.EventType.AnalogueOutputRequestFailed:
                return "Analogue Output Request Failed";

            case Enums.EventType.AnalogueOutputRequestCompleted:
                return "Analogue Output Request Completed";

            case Enums.EventType.AnalogueOutputCommandSuccessful:
                return "Analogue Output Command Successful";

            case Enums.EventType.BinaryOutputCommandSuccessful:
                return "Binary Output Command Successful";

            case Enums.EventType.ProcessingFile:
                return "Processing file";

            case Enums.EventType.FileImportSuccessful:
                return "File Import Successful";

            case Enums.EventType.FileImportFailed:
                return "File Import Failed";

            case Enums.EventType.InvalidDeviceIdentity:
                return "Invalid Device Identity";

            case Enums.EventType.WaitingForValidSerialNumber:
                return "Waiting for Valid Serial Number";

            case Enums.EventType.SerialNumberValidated:
                return "Serial Number Validated";

            case Enums.EventType.DeviceConfigFileImported:
                return "Device Config File Imported";

            case Enums.EventType.DeviceConfigFileImportFailed:
                return "Device Config File Import Failed";

            case Enums.EventType.StartupCompleted:
                return "Startup Completed";

            case Enums.EventType.StartupFailed:
                return "Startup Failed";

            case Enums.EventType.NeedTime:
                return "Need Time";

            case Enums.EventType.SmsReceivedFromRtu:
                return "SMS Message Received from RTU";

            case Enums.EventType.RtuCreated:
                return "RTU Created";

            case Enums.EventType.RtuAclResendRequested:
                return "ACL Resend Requested";

            case Enums.EventType.RtuAclCleared:
                return "ACL Cleared";

            case Enums.EventType.RtuAclEntryAdded:
                return "ACL Entry Added";

            case Enums.EventType.RtuAclEntryRemoved:
                return "ACL Entry Removed";

            case Enums.EventType.RtuAclEntryStored:
                return "ACL Entry Stored";

            case Enums.EventType.RingFromAuthorisedPhoneNumber:
                return "Ring From Authorised Phone Number";

            case Enums.EventType.RingFromUnauthorisedPhoneNumber:
                return "Ring From Unauthorised Phone Number";

            case Enums.EventType.SmsFromAuthorisedPhoneNumber:
                return "SMS From Authorised Phone Number";

            case Enums.EventType.SmsFromUnauthorisedPhoneNumber:
                return "SMS From Unauthorised Phone Number";

            case Enums.EventType.RtuAclEntryCleared:
                return "ACL Entry Cleared";

            case Enums.EventType.NoValidConfigFound:
                return "No Valid Config Found";

            case Enums.EventType.RtuLocked:
                return "RTU Locked";

            case Enums.EventType.RtuUnlocked:
                return "RTU Unlocked";

            case Enums.EventType.RtuNoteAdded:
                return "RTU Note Added";

            case Enums.EventType.RtuNoteEdited:
                return "RTU Note Edited";

            case Enums.EventType.RtuNoteDeleted:
                return "RTU Note Deleted";

            case Enums.EventType.RingBackRequested:
                return "Ring Back Requested";

            case Enums.EventType.RtuFirmwareAssigned:
                return "RTU Firmware Assigned";

            case Enums.EventType.RtuFirmwareUploadStarted:
                return "RTU Firmware Upload Started";

            case Enums.EventType.RtuFirmwareUploadSucceeded:
                return "RTU Firmware Upload Succeeded ";

            case Enums.EventType.RtuFirmwareUploadFailed:
                return "RTU Firmware Upload Failed";

            case Enums.EventType.RtuFirmwareUpgradeFailed:
                return "RTU Firmware Upgrade Failed";

            case Enums.EventType.RtuFirmwareUpgradeSucceeded:
                return "RTU Firmware Upgrade Succeeded";

            case Enums.EventType.RtuFirmwareUpgradeConfigurationFailed:
                return "RTU Firmware Upgrade Configuration Failed";

            case Enums.EventType.RtuCertificateCreated:
                return "RTU Certificate Created";

            case Enums.EventType.RtuCertificateDeleted:
                return "RTU Certificate Deleted";

            case Enums.EventType.InvalidRtuCertificate:
                return "Invalid RTU Certificate";

            case Enums.EventType.RtuCertificateUploaded:
                return "RTU Certificate Uploaded";

            case Enums.EventType.AnalogueOutputLevelHostReading:
                return "Analogue Output Level Host Reading";

            case Enums.EventType.AnmResultsFile:
                return "ANM Results File";

            case Enums.EventType.DisconnectedLocalClose:
                return "Disconnected (Local Close)";

            case Enums.EventType.OutputStatusClosed:
                return "Output Status Closed";

            case Enums.EventType.OutputStatusOpened:
                return "Output Status Opened";

            case Enums.EventType.LogicInitialised:
                return "Logic Initialised";

            case Enums.EventType.LogicRunCompletedControlModuleDisabled:
                return "Logic Run Completed (Control Module Disabled)";

            case Enums.EventType.LogicRunCompletedRemoteControlNotAvailable:
                return "Logic Run Completed (Remote Control Not Available)";

            case Enums.EventType.LogicRunCompletedNoViolations:
                return "Logic Run Completed (No Violations)";

            case Enums.EventType.LogicRunCompletedLatched:
                return "Logic Run Completed (Latched)";

            case Enums.EventType.LogicRunCompletedSetpointsCalculated:
                return "Logic Run Completed (Setpoints Calculated)";

            case Enums.EventType.LogicRunCompletedSetpointsDispatched:
                return "Logic Run Completed (Setpoints Dispatched)";

            case Enums.EventType.LogicRunCompletedControlRetry:
                return "Logic Run Completed (Control Retry)";

            case Enums.EventType.LogicError1MinimumDatasetNotAvailable:
                return "Logic Error 1 (Minimum Dataset Not Available)";

            case Enums.EventType.LogicError2UnexpectedConfiguration:
                return "Logic Error 2 (Unexpected Configuration)";

            case Enums.EventType.LogicError3StateEstimationFailure:
                return "Logic Error 3 (State Estimation Failure)";

            case Enums.EventType.LogicError4StateEstimationMismatch:
                return "Logic Error 4 (State Estimation Mismatch)";

            case Enums.EventType.LogicError5NoSolutionFound:
                return "Logic Error 5 (No Solution Found)";

            case Enums.EventType.LogicError6SetpointControlFailure:
                return "Logic Error 6 (Setpoint Control Failure)";

            case Enums.EventType.LogicFailed:
                return "Logic Failed";

            case Enums.EventType.LogicGracefulShutdown:
                return "Logic Graceful Shutdown";

            case Enums.EventType.StringValueReceived:
                return "String Value Received";

            case Enums.EventType.StringValueChanged:
                return "String Value Changed";

            case Enums.EventType.PostFirmwareUpgradeExtendedConfigurationFailed:
                return "Post firmware upgrade extended configuration failed";

            case Enums.EventType.RtuFailedToSendSms:
                return "RTU Failed To Send SMS";

            case Enums.EventType.LocalFirmwareUpdateStarted:
                return "Local firmware update started";

            case Enums.EventType.LocalFirmwareUpdateTimedOut:
                return "Local firmware update timed out";

            case Enums.EventType.RemoteFirmwareCheckStarted:
                return "Remote firmware check started";

            case Enums.EventType.RemoteFirmwareCheckTimedOut:
                return "Remote firmware check timed out";

            case Enums.EventType.RemoteFirmwareFileUnavailable:
                return "Remote firmware file unavailable";

            case Enums.EventType.RemoteFirmwareSameAsCurrentlyRunning:
                return "Remote firmware same as currently running";

            case Enums.EventType.FirmwareFileInvalid:
                return "Firmware file invalid";

            case Enums.EventType.NewFirmwareLoadingOrVerificationFailed:
                return "New firmware loading or verification failed";

            case Enums.EventType.NewFirmwareLoadingOrVerificationComplete:
                return "New firmware loading and verification complete";

            case Enums.EventType.NewFirmwareFirstBootTemporary:
                return "New firmware first boot (temporary)";

            case Enums.EventType.NewFirmwareTransitionComplete:
                return "New firmware transition complete";

            case Enums.EventType.NXBFieldNotSupported:
                return "NXB field not supported";

            case Enums.EventType.TemplateSyncEnabled:
                return "Template Sync Enabled";

            case Enums.EventType.TemplateSyncDisabled:
                return "Template Sync Disabled";

            case Enums.EventType.TemplateSyncCompleted:
                return "Template Sync Completed";

            case Enums.EventType.TemplateSyncFailed:
                return "Template Sync Failed";

            case Enums.EventType.BrownoutReset:
                return "Brownout Reset";

            case Enums.EventType.PQEvent:
                return "PQ Event";

            case Enums.EventType.PQIncompleteEvent:
                return "PQ Incomplete Event";

            case Enums.EventType.DeviceApiLate:
                return "Device API Late";

            case Enums.EventType.DeviceApiCommsRestored:
                return "Device API Comms Restored";

            case Enums.EventType.PQPendingEvent:
                return "PQ Pending Event";

            case Enums.EventType.FirmwareVersionChanged:
                return "Firmware Version Changed";

            case Enums.EventType.RtuConfigurationDownloadedByRtu:
                return "RTU Configuration Downloaded by RTU";

            case Enums.EventType.RtuConfigurationUploadedByRtu:
                return "RTU Configuration Uploaded by RTU";

            case Enums.EventType.RtuConfigurationImportedFromRtu:
                return "RTU Configuration Imported from RTU";

            case Enums.EventType.RtuConfigurationFailedImport:
                return "RTU Configuration Failed Import";

            case Enums.EventType.RtuConfigurationFailedExport:
                return "RTU Configuration Failed Export";

            case Enums.EventType.RtuConfigurationDownloadRequestedByRtu:
                return "RTU Configuration Download Requested by RTU";

            case Enums.EventType.RtuConfigurationNoChangesFoundForDownloadByRtu:
                return "RTU Configuration No Changes Found for Download by RTU";

            case Enums.EventType.RtuConfigurationMetadataRequestedByRtu:
                return "RTU Configuration Metadata Requested by RTU";

            case Enums.EventType.AnalogueRoutineDataloggingFailed:
                return "Analogue Routine Datalogging Failed";
                
            case Enums.EventType.AnalogueRoutineDataloggingRestored:
                return "Analogue Routine Datalogging Restored";
                
            case Enums.EventType.FileImportError:
                return "File Import Error";

            case Enums.EventType.FileImportWarning:
                return "File Import Warning";

            case Enums.EventType.PushNotificationsDisabled:
                return "Push Notifications have been disabled";

            case Enums.EventType.PushNotificationsEnabled:
                return "Push Notifications have been enabled";
            
            case Enums.EventType.DecommissioningCompleted:
                return "Decommissioning Completed";
            
            case Enums.EventType.DoubleBitInputIntermediate:
                return "Double-bit Input Intermediate";
            
            case Enums.EventType.DoubleBitInputOpen:
                return "Double-bit Input Open";
            
            case Enums.EventType.DoubleBitInputClosed:
                return "Double-bit Input Closed";
            
            case Enums.EventType.DoubleBitInputIndeterminate:
                return "Double-bit Input Indeterminate";

            default:
                return "Unknown";
        }
    }

    public static getEventLogTypesForPointSubType(pointSubType: Enums.PointSubType) {
        switch (pointSubType) {
            case Enums.PointSubType.RtuEventSource:
                return [
                    Enums.EventType.OtherUnknown,
                    Enums.EventType.PacketWaitTimerElapsed,
                    Enums.EventType.CatchAllTimerElapsed,
                    Enums.EventType.WatchdogTimerReset,
                    Enums.EventType.RtuLogBufferOverrun,
                    Enums.EventType.ProtocolError,
                    Enums.EventType.RtuLate,
                    Enums.EventType.ManualPollCallOK,
                    Enums.EventType.ManualPollCallFailed,
                    Enums.EventType.AutoPollCallOk,
                    Enums.EventType.AutoPollCallFailed,
                    Enums.EventType.ColdStart,
                    Enums.EventType.RtuInitiatedCall,
                    Enums.EventType.CommunicationRestored,
                    Enums.EventType.CallFailed,
                    Enums.EventType.DialoutFailedToConnect,
                    Enums.EventType.CommissioningCompleted,
                    Enums.EventType.SignatureLogBufferOverrun,
                    Enums.EventType.BinaryOutputPatternSet,
                    Enums.EventType.InsufficientEeprom,
                    Enums.EventType.BitPatternSetReadMismatchOnPL1,
                    Enums.EventType.TcpConnectionDroppedUnexpectedly,
                    Enums.EventType.InsufficientCredit,
                    Enums.EventType.NoAnswerFromRtu,
                    Enums.EventType.RtuBusy,
                    Enums.EventType.NoCarrierConnectingToRtu,
                    Enums.EventType.NoDialToneConnectingToRtu,
                    Enums.EventType.NoChannelAvailable,
                    Enums.EventType.WarmStart,
                    Enums.EventType.SmsSentToRtu,
                    Enums.EventType.SMSResponseTimerElapsed,
                    Enums.EventType.BinarySignatureLogReceived,
                    Enums.EventType.RtuDiagnostic,
                    Enums.EventType.CallbackRequested,
                    Enums.EventType.RingBackInitiated,
                    Enums.EventType.RtuParked,
                    Enums.EventType.RtuUnparked,
                    Enums.EventType.SiteNameChanged,
                    Enums.EventType.GroupChanged,
                    Enums.EventType.SerialNumberChanged,
                    Enums.EventType.UserData1Changed,
                    Enums.EventType.UserData2Changed,
                    Enums.EventType.UserData3Changed,
                    Enums.EventType.UserData4Changed,
                    Enums.EventType.UserData5Changed,
                    Enums.EventType.UserData6Changed,
                    Enums.EventType.LastMaintainedDateReset,
                    Enums.EventType.CallCountersReset,
                    Enums.EventType.RingBackResponseTimerElapsed,
                    Enums.EventType.ConnectionTimeout,
                    Enums.EventType.NoDataReceived,
                    Enums.EventType.ConnectionAuthenticationError,
                    Enums.EventType.EstablishConnectionInterrupted,
                    Enums.EventType.ConnectionToDeviceLost,
                    Enums.EventType.DisconnectedForCallBack,
                    Enums.EventType.DisconnectedForNewAlarm,
                    Enums.EventType.EepromBufferOverrun,
                    Enums.EventType.ScheduledBinaryOutputQueueFailed,
                    Enums.EventType.CommsSettingsSentToDevice,
                    Enums.EventType.AggressiveCallInModeEnabled,
                    Enums.EventType.AggressiveCallInModeDisabled,
                    Enums.EventType.AggressiveCallInModeManuallyDisabled,
                    Enums.EventType.SMSNotificationsDisabled,
                    Enums.EventType.SMTPNotificationsDisabled,
                    Enums.EventType.SMSNotificationsEnabled,
                    Enums.EventType.SMTPNotificationsEnabled,
                    Enums.EventType.SMSNotificationsLimitReached,
                    Enums.EventType.EmailReceivedFromUnit,
                    Enums.EventType.ColdstartCallDelayed,
                    Enums.EventType.RtuOffline,
                    Enums.EventType.RtuOnline,
                    Enums.EventType.Connected,
                    Enums.EventType.Disconnected,
                    Enums.EventType.BufferOverflow,
                    Enums.EventType.DeviceTrouble,
                    Enums.EventType.Connecting,
                    Enums.EventType.PollRequested,
                    Enums.EventType.PollSuccessful,
                    Enums.EventType.PollFailed,
                    Enums.EventType.Disconnecting,
                    Enums.EventType.ConnectionReset,
                    Enums.EventType.ConnectionActivityTimeout,
                    Enums.EventType.UnexpectedSerialNumber,
                    Enums.EventType.DeviceHealthy,
                    Enums.EventType.DisturbanceXMLParsingFailed,
                    Enums.EventType.ProcessingFile,
                    Enums.EventType.FileImportSuccessful,
                    Enums.EventType.FileImportFailed,
                    Enums.EventType.InvalidDeviceIdentity,
                    Enums.EventType.WaitingForValidSerialNumber,
                    Enums.EventType.SerialNumberValidated,
                    Enums.EventType.DeviceConfigFileImported,
                    Enums.EventType.DeviceConfigFileImportFailed,
                    Enums.EventType.StartupCompleted,
                    Enums.EventType.StartupFailed,
                    Enums.EventType.NeedTime,
                    Enums.EventType.SmsReceivedFromRtu,
                    Enums.EventType.RtuCreated,
                    Enums.EventType.RtuAclResendRequested,
                    Enums.EventType.RtuAclCleared,
                    Enums.EventType.RtuAclEntryAdded,
                    Enums.EventType.RtuAclEntryRemoved,
                    Enums.EventType.RtuAclEntryStored,
                    Enums.EventType.RingFromAuthorisedPhoneNumber,
                    Enums.EventType.RingFromUnauthorisedPhoneNumber,
                    Enums.EventType.SmsFromAuthorisedPhoneNumber,
                    Enums.EventType.SmsFromUnauthorisedPhoneNumber,
                    Enums.EventType.RtuAclEntryCleared,
                    Enums.EventType.NoValidConfigFound,
                    Enums.EventType.RtuLocked,
                    Enums.EventType.RtuUnlocked,
                    Enums.EventType.RtuNoteAdded,
                    Enums.EventType.RtuNoteEdited,
                    Enums.EventType.RtuNoteDeleted,
                    Enums.EventType.RingBackRequested,
                    Enums.EventType.RtuFirmwareAssigned,
                    Enums.EventType.RtuFirmwareUploadStarted,
                    Enums.EventType.RtuFirmwareUploadSucceeded,
                    Enums.EventType.RtuFirmwareUploadFailed,
                    Enums.EventType.RtuFirmwareUpgradeFailed,
                    Enums.EventType.RtuFirmwareUpgradeSucceeded,
                    Enums.EventType.RtuFirmwareUpgradeConfigurationFailed,
                    Enums.EventType.RtuCertificateCreated,
                    Enums.EventType.RtuCertificateDeleted,
                    Enums.EventType.InvalidRtuCertificate,
                    Enums.EventType.RtuCertificateUploaded,
                    Enums.EventType.AnmResultsFile,
                    Enums.EventType.DisconnectedLocalClose,
                    Enums.EventType.LogicInitialised,
                    Enums.EventType.LogicRunCompletedControlModuleDisabled,
                    Enums.EventType.LogicRunCompletedRemoteControlNotAvailable,
                    Enums.EventType.LogicRunCompletedNoViolations,
                    Enums.EventType.LogicRunCompletedLatched,
                    Enums.EventType.LogicRunCompletedSetpointsCalculated,
                    Enums.EventType.LogicRunCompletedSetpointsDispatched,
                    Enums.EventType.LogicRunCompletedControlRetry,
                    Enums.EventType.LogicError1MinimumDatasetNotAvailable,
                    Enums.EventType.LogicError2UnexpectedConfiguration,
                    Enums.EventType.LogicError3StateEstimationFailure,
                    Enums.EventType.LogicError4StateEstimationMismatch,
                    Enums.EventType.LogicError5NoSolutionFound,
                    Enums.EventType.LogicError6SetpointControlFailure,
                    Enums.EventType.LogicFailed,
                    Enums.EventType.LogicGracefulShutdown,
                    Enums.EventType.PostFirmwareUpgradeExtendedConfigurationFailed,
                    Enums.EventType.LocalFirmwareUpdateStarted,
                    Enums.EventType.LocalFirmwareUpdateTimedOut,
                    Enums.EventType.RemoteFirmwareCheckStarted,
                    Enums.EventType.RemoteFirmwareCheckTimedOut,
                    Enums.EventType.RemoteFirmwareFileUnavailable,
                    Enums.EventType.RemoteFirmwareSameAsCurrentlyRunning,
                    Enums.EventType.FirmwareFileInvalid,
                    Enums.EventType.NewFirmwareLoadingOrVerificationFailed,
                    Enums.EventType.NewFirmwareLoadingOrVerificationComplete,
                    Enums.EventType.NewFirmwareFirstBootTemporary,
                    Enums.EventType.NewFirmwareTransitionComplete,
                    Enums.EventType.NXBFieldNotSupported,
                    Enums.EventType.TemplateSyncEnabled,
                    Enums.EventType.TemplateSyncDisabled,
                    Enums.EventType.TemplateSyncCompleted,
                    Enums.EventType.TemplateSyncFailed,
                    Enums.EventType.BrownoutReset,
                    Enums.EventType.DeviceApiLate,
                    Enums.EventType.DeviceApiCommsRestored,
                    Enums.EventType.FirmwareVersionChanged,
                    Enums.EventType.RtuConfigurationDownloadedByRtu,
                    Enums.EventType.RtuConfigurationUploadedByRtu,
                    Enums.EventType.RtuConfigurationImportedFromRtu,
                    Enums.EventType.RtuConfigurationFailedImport,
                    Enums.EventType.RtuConfigurationFailedExport,
                    Enums.EventType.RtuConfigurationDownloadRequestedByRtu,
                    Enums.EventType.RtuConfigurationNoChangesFoundForDownloadByRtu,
                    Enums.EventType.RtuConfigurationMetadataRequestedByRtu,
                    Enums.EventType.FileImportError,
                    Enums.EventType.FileImportWarning,
                    Enums.EventType.PushNotificationsDisabled,
                    Enums.EventType.PushNotificationsEnabled,
                    Enums.EventType.DecommissioningCompleted
                ];
            case Enums.PointSubType.Rssi:
                return [
                    Enums.EventType.CommsDeviceFailedtoRespond,
                    Enums.EventType.NetworkUnavailable,
                    Enums.EventType.HostBusy,
                    Enums.EventType.NoCarrierOnCommsdevice,
                    Enums.EventType.DialToConnectTimerElapsed,
                    Enums.EventType.ErrorReadingSimCard,
                    Enums.EventType.SmsSendingFailed,
                    Enums.EventType.SignalStrengthReceived,
                    Enums.EventType.ModemRebooted,
                    Enums.EventType.PdpInvalidContext,
                    Enums.EventType.PdpAccountInvalid,
                    Enums.EventType.PdpShutdownFailure,
                    Enums.EventType.PdpSetupCancelled,
                    Enums.EventType.PdpTooManyActiveAccounts,
                    Enums.EventType.PdpConflictWithHigherPriorityAccount,
                    Enums.EventType.PdpTooManyActiveUsers,
                    Enums.EventType.PdpNonExistentAccount,
                    Enums.EventType.PdpStopAtUserRequest,
                    Enums.EventType.PdpAuthenticationFailed,
                    Enums.EventType.PdpBearerFailedConnect,
                    Enums.EventType.PdpRemoteServerBusy,
                    Enums.EventType.PdpRemoteServerRefused,
                    Enums.EventType.PdpBearerBusy,
                    Enums.EventType.PdpLineBusy,
                    Enums.EventType.PdpUnknownError,
                    Enums.EventType.PdpInvalidParameters,
                    Enums.EventType.IPNoConnection,
                    Enums.EventType.IPConnecting,
                    Enums.EventType.IPConnected,
                    Enums.EventType.IPConnectionBusy,
                    Enums.EventType.IPOtherError,
                    Enums.EventType.CsdConnectionDroppedUnexpectedly,
                    Enums.EventType.GprsConnectionNotSupported,
                    Enums.EventType.SmsMessageReceived,
                    Enums.EventType.ErrorWithModemSmsMemory,
                    Enums.EventType.StxReceivedDuringIncomingMessage,
                    Enums.EventType.CorruptIncomingMessageIgnored,
                    Enums.EventType.UnspecifiedConnectionError,
                    Enums.EventType.PdpActivationFailure,
                    Enums.EventType.IPConnectionFailure,
                    Enums.EventType.IODeviceOffline,
                    Enums.EventType.IODeviceOnline,
                    Enums.EventType.ModemFailedtoShutdown,
                    Enums.EventType.RtuFailedToSendSms
                ];
            case Enums.PointSubType.BinaryInput:
                return [
                    Enums.EventType.BinaryLogsBufferOverrun,
                    Enums.EventType.BinaryInputClosed,
                    Enums.EventType.BinaryInputOpen,
                    Enums.EventType.FlutterDetectionAutoDisable,
                    Enums.EventType.FlutterDetectionAutoEnable,
                    Enums.EventType.BinaryInputStatusOpened,
                    Enums.EventType.BinaryInputStatusClosed,
                    Enums.EventType.BinaryInputClosedManualOverride,
                    Enums.EventType.BinaryInputOpenedManualOverride,
                    Enums.EventType.PQEvent,
                    Enums.EventType.PQIncompleteEvent,
                    Enums.EventType.PQPendingEvent
                ];
            case Enums.PointSubType.AnalogueInput:
                return [
                    Enums.EventType.AnalogueLogsBufferoverrun,
                    Enums.EventType.AnalogueThresholdCrossedRising,
                    Enums.EventType.AnalogueThresholdCrossedFalling,
                    Enums.EventType.AnalogueThresholdCrossed,
                    Enums.EventType.RoutineAnalogueLevelReading,
                    Enums.EventType.RoutineAnalogueLevelHostReading,
                    Enums.EventType.LowLowThresholdCrossedRising,
                    Enums.EventType.LowThresholdCrossedRising,
                    Enums.EventType.HighThresholdCrossedRising,
                    Enums.EventType.HighHighThresholdCrossedRising,
                    Enums.EventType.LowLowThresholdCrossedFalling,
                    Enums.EventType.LowThresholdCrossedFalling,
                    Enums.EventType.HighThresholdCrossedFalling,
                    Enums.EventType.HighHighThresholdCrossedFalling,
                    Enums.EventType.InputCalibratedNewReading,
                    Enums.EventType.SignatureLogReceived,
                    Enums.EventType.AnalogueAverage,
                    Enums.EventType.AnalogueInputInvalid,
                    Enums.EventType.AnalogueInputValid,
                    Enums.EventType.DeadbandCrossed,
                    Enums.EventType.AnalogueRoutineDataloggingFailed,
                    Enums.EventType.AnalogueRoutineDataloggingRestored
                ];
            case Enums.PointSubType.CounterInput:
                return [
                    Enums.EventType.CounterLogsBufferOverrun,
                    Enums.EventType.CounterThresholdReached,
                    Enums.EventType.CounterValueReceived,
                    Enums.EventType.DataLoggingBufferOverrun,
                    Enums.EventType.Counter30MinuteDatalogging,
                    Enums.EventType.CounterDeltaValue
                ];
            case Enums.PointSubType.BinaryOutput:
                return [
                    Enums.EventType.OutputClosed,
                    Enums.EventType.OutputOpened,
                    Enums.EventType.BinaryOutputRequestSent,
                    Enums.EventType.BinaryOutputRequestFailed,
                    Enums.EventType.BinaryOutputRequestCompleted,
                    Enums.EventType.BinaryOutputCommandTimeout,
                    Enums.EventType.BinaryOutputCommandRejected,
                    Enums.EventType.BinaryOutputCommandSuccessful,
                    Enums.EventType.OutputStatusClosed,
                    Enums.EventType.OutputStatusOpened
                ];
            case Enums.PointSubType.GpsPosition:
                return [
                    Enums.EventType.PositionReceived,
                    Enums.EventType.PositionRequested,
                    Enums.EventType.LiveTrackingEnabled,
                    Enums.EventType.LiveTrackingDisabled,
                    Enums.EventType.LiveTrackingAutoDisabled,
                    Enums.EventType.LiveTrackingAutoEnabled,
                    Enums.EventType.PositionLogBufferOverrun,
                    Enums.EventType.StartedMoving,
                    Enums.EventType.StoppedMoving
                ];
            case Enums.PointSubType.RtuTemperature:
                return [
                    Enums.EventType.RtuTemperatureReport
                ];
            case Enums.PointSubType.RtuBattery:
                return [
                    Enums.EventType.RtuBatteryOk,
                    Enums.EventType.RtuBatteryLow,
                    Enums.EventType.RtuBatteryOkManualOverride
                ];
            case Enums.PointSubType.RtuPower:
                return [
                    Enums.EventType.PowerFailed,
                    Enums.EventType.PowerRestored
                ];
            case Enums.PointSubType.PodVoltagePoint:
                return [
                    Enums.EventType.PodUndervoltage,
                    Enums.EventType.PodOverVoltage,
                    Enums.EventType.PodUndervoltageRestored,
                    Enums.EventType.PodOverVoltageRestored
                ];
            case Enums.PointSubType.FpiStatus:
                return [
                    Enums.EventType.TransientInstantaneousFault,
                    Enums.EventType.TransientSEFFault,
                    Enums.EventType.PermanentInstantaneousFault,
                    Enums.EventType.PermanentSefFault,
                    Enums.EventType.FaultReset
                ];
            case Enums.PointSubType.Ignition:
                return [
                    Enums.EventType.IgnitionOn,
                    Enums.EventType.IgnitionOff
                ];
            case Enums.PointSubType.AnalogueOutput:
                return [
                    Enums.EventType.AnalogueOutputLevelReading,
                    Enums.EventType.AnalogueOutputCommandTimeout,
                    Enums.EventType.AnalogueOutputCommandRejected,
                    Enums.EventType.AnalogueOutputRequestSent,
                    Enums.EventType.AnalogueOutputRequestFailed,
                    Enums.EventType.AnalogueOutputRequestCompleted,
                    Enums.EventType.AnalogueOutputCommandSuccessful,
                    Enums.EventType.AnalogueOutputLevelHostReading
                ];
            case Enums.PointSubType.StringInput:
                return [
                    Enums.EventType.StringValueReceived,
                    Enums.EventType.StringValueChanged
                ];
            case Enums.PointSubType.DoubleBitInput:
                return [
                    Enums.EventType.DoubleBitInputIntermediate,
                    Enums.EventType.DoubleBitInputOpen,
                    Enums.EventType.DoubleBitInputClosed,
                    Enums.EventType.DoubleBitInputIndeterminate
                ]
            default:
                return [];
        }
    }

    public static getUnnotifiableEventTypes(
        rtuType: Enums.RtuType,
        firmwareVersion: number,
        generatesBinStatusEvents = true,
        canViewNxDiagnosticEvents = false) 
    {
        const excludedEventTypes = [
            Enums.EventType.RoutineAnalogueLevelReading,
            Enums.EventType.RoutineAnalogueLevelHostReading,
            Enums.EventType.AnalogueAverage,
            Enums.EventType.AnalogueOutputLevelReading,
            Enums.EventType.AnalogueOutputLevelHostReading,
            ...!canViewNxDiagnosticEvents ? this.NexusDiagnosticEventLogTypes : [],
            ...generatesBinStatusEvents
                ? [Enums.EventType.BinaryInputOpen, Enums.EventType.BinaryInputClosed]
                : [Enums.EventType.BinaryInputStatusOpened, Enums.EventType.BinaryInputStatusClosed]
        ];

        if (rtuType === Enums.RtuType.Nexus12 || rtuType === Enums.RtuType.Nexus22 || rtuType === Enums.RtuType.Nexus41 || rtuType === Enums.RtuType.Nexus42 || rtuType === Enums.RtuType.Nexus43 ||
            (rtuType === Enums.RtuType.Nexus11 && firmwareVersion >= 23) ||
            (rtuType === Enums.RtuType.Nexus21 && firmwareVersion >= 14) ||
            (rtuType === Enums.RtuType.Nexus30 && firmwareVersion >= 23) ||
            (rtuType === Enums.RtuType.Nexus50 && firmwareVersion >= 23))
        {
            excludedEventTypes.push(Enums.EventType.AnalogueThresholdCrossedRising);
            excludedEventTypes.push(Enums.EventType.AnalogueThresholdCrossedFalling);
        }

        switch (rtuType) {
            case Enums.RtuType.Nexus10:
            case Enums.RtuType.Nexus11:
            case Enums.RtuType.Nexus12:
            case Enums.RtuType.Nexus20:
            case Enums.RtuType.Nexus21:
            case Enums.RtuType.Nexus22:
            case Enums.RtuType.Nexus30:
            case Enums.RtuType.Nexus41:
            case Enums.RtuType.Nexus42:
            case Enums.RtuType.Nexus43:
            case Enums.RtuType.Nexus50:
            case Enums.RtuType.Nexus52:
            case Enums.RtuType.NexusEnvoy:
                return [
                    ...excludedEventTypes,
                    Enums.EventType.AnalogueThresholdCrossed
                ];
            case Enums.RtuType.GridKeyStatisticalMcu:
            case Enums.RtuType.GridKeyInstantaneousMcu:
            case Enums.RtuType.GridKeyLVCMMcu:
                return [
                    ...excludedEventTypes,
                    Enums.EventType.InputCalibratedNewReading,
                    Enums.EventType.AnalogueThresholdCrossed,
                    Enums.EventType.DeadbandCrossed,
                    Enums.EventType.AnalogueInputInvalid,
                    Enums.EventType.AnalogueInputValid,
                    Enums.EventType.AnalogueLogsBufferoverrun,
                    Enums.EventType.AnalogueThresholdCrossedRising,
                    Enums.EventType.AnalogueThresholdCrossedFalling
                ];
            case Enums.RtuType.PQMonitor:
            case Enums.RtuType.SEL735:
            case Enums.RtuType.ION7650:
                return [
                    ...excludedEventTypes,
                    Enums.EventType.BinaryInputStatusOpened,
                    Enums.EventType.BinaryInputStatusClosed,
                    Enums.EventType.BinaryInputClosedManualOverride,
                    Enums.EventType.BinaryInputOpenedManualOverride,
                    Enums.EventType.BinaryLogsBufferOverrun,
                    Enums.EventType.FlutterDetectionAutoDisable,
                    Enums.EventType.FlutterDetectionAutoEnable,
                    Enums.EventType.InputCalibratedNewReading,
                    Enums.EventType.AnalogueThresholdCrossed,
                    Enums.EventType.AnalogueThresholdCrossedRising,
                    Enums.EventType.AnalogueThresholdCrossedFalling,
                    Enums.EventType.DeadbandCrossed,
                    Enums.EventType.AnalogueInputInvalid,
                    Enums.EventType.AnalogueInputValid,
                    Enums.EventType.AnalogueLogsBufferoverrun,
                    Enums.EventType.SignatureLogReceived
                ];
            default:
                return excludedEventTypes;
        }
    }

    /**
     * Converts an System Backup status to human readable string.
     */
    public static getSystemBackupStatusString(systemBackupStatus: Enums.SystemBackupStatus) {
        switch (systemBackupStatus) {
            case Enums.SystemBackupStatus.Successful:
                return "Successful";

            case Enums.SystemBackupStatus.Failed:
                return "Failed";

            case Enums.SystemBackupStatus.InProgress:
                return "In Progress";

            default:
                return "Unknown";
        }
    }

    /**
     * Gets a friendly name for the RTU Template type.
     * @param rtuTemplateType
     * @returns string description
     */
    public static getRtuTemplateTypeDescription(rtuTemplateType: Enums.RtuTemplateType) {
        switch (rtuTemplateType) {
            case Enums.RtuTemplateType.CommsTemplate:
                return "Comms Template";
            case Enums.RtuTemplateType.CombinedTemplate:
                return "Combined Template";
            case Enums.RtuTemplateType.DeviceTemplate:
                return "Device Template";
            default:
                return "Template";
        }
    }

    /**
    * Gets a friendly name for the Protocol
    * @param protocol
    * @returns string description
    */
    public static getProtocolDescription(protocol: Enums.Protocol) {
        switch (protocol) {
            case Enums.Protocol.None:
                return "None";
            case Enums.Protocol.BowdenFPI:
                return "Bowden FPI";
            case Enums.Protocol.Nexus:
                return "Nexus";
            case Enums.Protocol.Kehui:
                return "Kehui";
            case Enums.Protocol.iHostSOAPXML:
                return "iHost SOAP XML";
            case Enums.Protocol.KelmanSOAPXML:
                return "Kelman SOAP XML";
            case Enums.Protocol.SubNet:
                return "sub.net";
            case Enums.Protocol.DNP3:
                return "DNP3";
            case Enums.Protocol.GridKey:
                return "GridKey";
            case Enums.Protocol.Nortroll:
                return "Nortroll";
            case Enums.Protocol.IPECPDAlarmSMS:
                return "IPEC PD Alarm SMS"
            case Enums.Protocol.Iccp:
                return "ICCP";
            case Enums.Protocol.Iec104:
                return "IEC 60870-5-104";
            case Enums.Protocol.ModbusTcp:
                return "ModbusTcp";
            case Enums.Protocol.Mqtt:
                return "MQTT";
            case Enums.Protocol.EConnectApi:
                return "E.Connect API";
            case Enums.Protocol.SelAscii:
                return "SEL ASCII";
            case Enums.Protocol.ION:
                return "ION";
            case Enums.Protocol.FlexiblePowerApi:
                return "Flexible Power API";
            case Enums.Protocol.MqttSparkplug:
                return "MQTT Sparkplug";
            default:
                return "Unknown";
        }
    }

    /**
    * Converts an RtuCommsStatus into a human readable string
    */
    public static getRtuCommsStatusString(rtuCommsStatus: Enums.RtuCommsStatus) {
        switch (rtuCommsStatus) {
            case Enums.RtuCommsStatus.Undefined:
                return "Undefined";
            case Enums.RtuCommsStatus.NoContact:
                return "No Contact";
            case Enums.RtuCommsStatus.LookingForFreeLine:
                return "Looking For Free Line";
            case Enums.RtuCommsStatus.PollRequestQueued:
                return "Poll Request Queued";
            case Enums.RtuCommsStatus.Successful:
                return "Successful";
            case Enums.RtuCommsStatus.Dialling:
                return "Dialling";
            case Enums.RtuCommsStatus.Answering:
                return "Answering";
            case Enums.RtuCommsStatus.Connected:
                return "Connected";
            case Enums.RtuCommsStatus.Connecting:
                return "Connecting";
            case Enums.RtuCommsStatus.Disconnected:
                return "Disconnected";
            case Enums.RtuCommsStatus.Disconnecting:
                return "Disconnecting";
            case Enums.RtuCommsStatus.IdentifyingRTU:
                return "Identifying RTU";
            case Enums.RtuCommsStatus.GettingBinaryLogs:
                return "Getting Binary Logs";
            case Enums.RtuCommsStatus.GettingCounterLogs:
                return "Getting Counter Logs";
            case Enums.RtuCommsStatus.GettingAnalogueLogs:
                return "Getting Analogue Logs";
            case Enums.RtuCommsStatus.GettingDataLoggingLogs:
                return "Getting Data Logging Logs";
            case Enums.RtuCommsStatus.SendingConfiguration:
                return "Sending Configuration";
            case Enums.RtuCommsStatus.HangingUp:
                return "Hanging Up";
            case Enums.RtuCommsStatus.NoChannelAvailable:
                return "No Channel Available";
            case Enums.RtuCommsStatus.NoDialTone:
                return "No Dial Tone";
            case Enums.RtuCommsStatus.RTUBusy:
                return "RTU Busy";
            case Enums.RtuCommsStatus.NoAnswer:
                return "No Answer";
            case Enums.RtuCommsStatus.ProtocolFailure:
                return "Protocol Failure";
            case Enums.RtuCommsStatus.PacketTimedOut:
                return "Packet Timed Out";
            case Enums.RtuCommsStatus.CallTimeout:
                return "Call Timeout";
            case Enums.RtuCommsStatus.WrongUnitAnsweredCall:
                return "Wrong Unit Answered Call";
            case Enums.RtuCommsStatus.CallFailed:
                return "Call Failed";
            case Enums.RtuCommsStatus.RequestingPosition:
                return "Requesting Position";
            case Enums.RtuCommsStatus.TimedOutNoResponse:
                return "Timed Out: No Response";
            case Enums.RtuCommsStatus.LiveTracking:
                return "Live Tracking";
            case Enums.RtuCommsStatus.AwaitingConfirmation:
                return "Awaiting Confirmation";
            case Enums.RtuCommsStatus.GettingSignatureLogs:
                return "Getting Signature Logs";
            case Enums.RtuCommsStatus.SMSSendFailed:
                return "SMS Send Failed";
            case Enums.RtuCommsStatus.AutoLiveTracking:
                return "Auto Live Tracking";
            case Enums.RtuCommsStatus.CommsFailed:
                return "Comms Failed";
            case Enums.RtuCommsStatus.InsufficientCredit:
                return "Insufficient Credit";
            case Enums.RtuCommsStatus.GettingSystemLogs:
                return "Getting System Logs";
            case Enums.RtuCommsStatus.NoCarrier:
                return "No Carrier";
            case Enums.RtuCommsStatus.GettingPositionLogs:
                return "Getting Position Logs";
            case Enums.RtuCommsStatus.SendingSMS:
                return "Sending SMS";
            case Enums.RtuCommsStatus.SMSSentAwaitingResponse:
                return "SMS Sent - Awaiting Response";
            case Enums.RtuCommsStatus.AwaitingLogon:
                return "Awaiting Logon";
            case Enums.RtuCommsStatus.GettingSettings:
                return "Getting Settings";
            case Enums.RtuCommsStatus.ReceivingIndexFile:
                return "Receiving Index File";
            case Enums.RtuCommsStatus.ReceivingEventFiles:
                return "Receiving Event Files";
            case Enums.RtuCommsStatus.ReceivingACCheckFile:
                return "Receiving Manual Trigger File";
            case Enums.RtuCommsStatus.RingBackRequested:
                return "Ring Back Requested";
            case Enums.RtuCommsStatus.CallIdle:
                return "Call Idle";
            case Enums.RtuCommsStatus.ReceivingTestFile:
                return "Receiving Test File";
            case Enums.RtuCommsStatus.PingingRTU:
                return "Pinging RTU";
            case Enums.RtuCommsStatus.ConnectionCancelled:
                return "Connection Cancelled";
            case Enums.RtuCommsStatus.ConnectionFailed:
                return "Connection Failed";
            case Enums.RtuCommsStatus.ConnectionTimeout:
                return "Connection Timeout";
            case Enums.RtuCommsStatus.UploadingFirmware:
                return "Uploading Firmware";
            case Enums.RtuCommsStatus.UpgradingFirmware:
                return "Upgrading Firmware";
            case Enums.RtuCommsStatus.RequestingConfiguration:
                return "Requesting Configuration";
            default:
                return "Unknown";
        }
    }

    public static getRtuCommsTypeDescription(rtuCommsType: Enums.RtuCommsType): string | undefined {
        switch (rtuCommsType) {
            case Enums.RtuCommsType.None:
                return "None";
            case Enums.RtuCommsType.CircuitSwitchedData:
                return "Circuit Switched Data";
            case Enums.RtuCommsType.DirectSerial:
                return "Direct Serial";
            case Enums.RtuCommsType.TcpIp:
                return "TCP/IP";
            case Enums.RtuCommsType.UdpIp:
                return "UDP/IP";
            case Enums.RtuCommsType.SMS:
                return "SMS";
            case Enums.RtuCommsType.TcpIpSms:
                return "TCP/IP and SMS";
            case Enums.RtuCommsType.Soap:
                return "SOAP Interface";
            case Enums.RtuCommsType.TcpIpRing:
                return "TCP/IP (RING initiated)";
            case Enums.RtuCommsType.Dialup:
                return "Dialup";
            case Enums.RtuCommsType.Icmp:
                return "ICMP";
            default:
                return undefined;
        }
    }

    /**
    * Gets a friendly name for the PQMeasurandSet
    * @param pqMeasurandSet
    * @returns string description
    */
    public static getPQMeasurandSetDescription(pqMeasurandSet: Enums.PQMeasurandSet) {
        switch (pqMeasurandSet) {
            case Enums.PQMeasurandSet.Frequency:
                return "Frequency";
            case Enums.PQMeasurandSet.Power:
                return "Power";
            case Enums.PQMeasurandSet.VoltageRms:
                return "Voltage RMS";
            case Enums.PQMeasurandSet.VoltageHarmonics:
                return "Voltage Harmonics";
            case Enums.PQMeasurandSet.VoltageInterharmonics:
                return "Voltage Interharmonics";
            case Enums.PQMeasurandSet.VoltageThd:
                return "Voltage THD";
            case Enums.PQMeasurandSet.VoltageUnbalance:
                return "Voltage Unbalance";   
            case Enums.PQMeasurandSet.Flicker:
                return "Flicker";   
            case Enums.PQMeasurandSet.CurrentRms:
                return "Current RMS";  
            case Enums.PQMeasurandSet.CurrentHarmonics:
                return "Current Harmonics"; 
            case Enums.PQMeasurandSet.CurrentThd:
                return "Current THD"; 
            case Enums.PQMeasurandSet.VoltageRmsFastSampling:
                return "Voltage RMS (fast sampling)";
            default:
                return "Unknown";
        }
    }

    /**
    * Gets a friendly name for the RtuFileProcessStatus
    * @param lastFileImportResult
    * @returns string description
    */
    public static getLastFileImportResultDescription(lastFileImportResult: Enums.RtuFileProcessStatus) {
        switch (lastFileImportResult) {
            case Enums.RtuFileProcessStatus.Pending:
                return "Pending";
            case Enums.RtuFileProcessStatus.InProgress:
                return "In Progress";
            case Enums.RtuFileProcessStatus.Successful:
                return "Successful";
            case Enums.RtuFileProcessStatus.Failed:
                return "Failed";
            default:
                return "Unknown";
        }
    }

    /**
    * Gets a friendly name for the PhaseArrangement
    * @param phaseArrangement
    * @returns string description
    */
    public static getPhaseArrangementDescription(phaseArrangement: Enums.PhaseArrangement) {
        switch (phaseArrangement) {
            case Enums.PhaseArrangement.SinglePhase:
                return "Single Phase";
            case Enums.PhaseArrangement.ThreePhaseWyeStar:
                return "Three Phase Wye/Star";
            case Enums.PhaseArrangement.ThreePhaseDelta:
                return "Three Phase Delta";
            default:
                return "Unknown";
        }
    }

    /**
    * Gets a friendly name for the PQValueScaling
    * @param pqValueScaling
    * @returns string description
    */
    public static getPQValueScalingDescription(pqValueScaling: Enums.PQValueScaling) {
        switch (pqValueScaling) {
            case Enums.PQValueScaling.DisplayValue:
                return "";
            case Enums.PQValueScaling.PercentageOfFundamental:
                return "% of fundamental";
            case Enums.PQValueScaling.PercentageOfNominal:
                return "% of nominal";
            case Enums.PQValueScaling.PercentageOfLimit:
                return "% of limit";
            case Enums.PQValueScaling.PerUnitOfNominal:
                return "pu";
            default:
                return undefined;
        }
    }

    public static isPQUnit(rtuType: Enums.RtuType) {
        switch (rtuType) {
            case Enums.RtuType.PQMonitor:
            case Enums.RtuType.SEL735:
            case Enums.RtuType.ION7650:
                return true;
            default:
                return false;
        }
    }

    public static isNavigatorUnit(rtuType: Enums.RtuType) {
        switch (rtuType) {
            case Enums.RtuType.NavigatorRS:
            case Enums.RtuType.PoleMaster:
            case Enums.RtuType.PoleMasterRail:
                return true;
            default:
                return false;
        }
    }

    /**
    * Gets a friendly name for the SystemLogType
    * @param systemLogType
    * @returns string description
    */
    public static getSystemLogTypeDescription(systemLogType: Enums.SystemLogType) {
        switch (systemLogType) {
            case Enums.SystemLogType.CSMStarted: 
                return "CSM Started";
            case Enums.SystemLogType.CSMFailed: 
                return "CSM Failed";
            case Enums.SystemLogType.CSMAdded: 
                return "CSM Added";
            case Enums.SystemLogType.CSMDeleted: 
                return "CSM Deleted";
            case Enums.SystemLogType.CSMSettingsChanged: 
                return "CSM Settings Changed";
            case Enums.SystemLogType.CSMLineAdded: 
                return "CSM Line Added";
            case Enums.SystemLogType.CSMLineDeleted: 
                return "CSM Line Deleted";
            case Enums.SystemLogType.CSMLineSettingsChanged: 
                return "CSM Line Settings Changed";
            case Enums.SystemLogType.CSMShutdown: 
                return "CSM Shutdown";
            case Enums.SystemLogType.TMStarted: 
                return "TM Started";
            case Enums.SystemLogType.TMShutdown: 
                return "TM Shutdown";
            case Enums.SystemLogType.TMAdded: 
                return "Task Manager Added";
            case Enums.SystemLogType.TMDeleted: 
                return "Task Manager Deleted";
            case Enums.SystemLogType.TMTaskDeleted: 
                return "Task Deleted";
            case Enums.SystemLogType.TMSettingsChanged: 
                return "Task Manager Settings Changed";
            case Enums.SystemLogType.TMTaskSettingsChanged: 
                return "Task Settings Changed";
            case Enums.SystemLogType.CallfromUnknownRTU: 
                return "Call from Unknown RTU";
            case Enums.SystemLogType.RTUSerialNoMismatch: 
                return "RTU Serial Number does not match expected value";
            case Enums.SystemLogType.NewUserAdded: 
                return "New User Added";
            case Enums.SystemLogType.UserAccountToAddressBookEntryLinkFailed: 
                return "User account to address book entry link failed";
            case Enums.SystemLogType.UserDeleted: 
                return "User Deleted";
            case Enums.SystemLogType.NewRTUCreated: 
                return "New RTU Created";
            case Enums.SystemLogType.RTUDeleted: 
                return "RTU Deleted";
            case Enums.SystemLogType.ProtocolError: 
                return "Protocol Error";
            case Enums.SystemLogType.LineFailed: 
                return "Line Failed";
            case Enums.SystemLogType.LineRestored: 
                return "Line Restored";
            case Enums.SystemLogType.DatabaseFailed: 
                return "Database Failed";
            case Enums.SystemLogType.DatabaseRestored: 
                return "Database Restored";
            case Enums.SystemLogType.CSMStartupFailed: 
                return "CSM Startup Failed";
            case Enums.SystemLogType.SendConfigPacketFailed: 
                return "Send Config Packet Failed";
            case Enums.SystemLogType.ScadaInterfaceStarted: 
                return "SCADA Interface Started";
            case Enums.SystemLogType.ScadaInterfaceStopped: 
                return "SCADA Interface Stopped";
            case Enums.SystemLogType.ScadaInterfaceFailed: 
                return "SCADA Interface Failed";
            case Enums.SystemLogType.ScadaLinkOK: 
                return "SCADA Link OK";
            case Enums.SystemLogType.ScadaLinkDown: 
                return "SCADA Link Down";
            case Enums.SystemLogType.TaskLate: 
                return "Task Late";
            case Enums.SystemLogType.FailedCallfromUnknownCallerLineID: 
                return "Failed Call from Unknown Caller Line ID";
            case Enums.SystemLogType.ConfigQueueEntryAdded: 
                return "Config Queue Entry Added";
            case Enums.SystemLogType.ConfigQueueEntryCancelled: 
                return "Config Queue Entry Cancelled";
            case Enums.SystemLogType.SMSmessageFromUnknownNumber: 
                return "SMS message from unknown number";
            case Enums.SystemLogType.NoDataReceivedOnLine: 
                return "No Data Received on Line";
            case Enums.SystemLogType.TaskManagerTaskExecutionFailure: 
                return "TaskManager Task Execution Failure";
            case Enums.SystemLogType.TaskManagerFailed: 
                return "Task Manager Failed";
            case Enums.SystemLogType.NewCustomerCreated: 
                return "New Customer Created";
            case Enums.SystemLogType.CustomerDeleted: 
                return "Customer Deleted";
            case Enums.SystemLogType.AssignedUserGroupChanged: 
                return "Assigned User Group Changed";
            case Enums.SystemLogType.SMSCommandReceived: 
                return "SMS Command Received";
            case Enums.SystemLogType.ModemReset: 
                return "Modem Reset";
            case Enums.SystemLogType.ModemFailedToReset: 
                return "Modem Failed to Reset";
            case Enums.SystemLogType.MessagingLimitExceeded: 
                return "Messaging Limit Exceeded";
            case Enums.SystemLogType.MessagingDisabled: 
                return "Messaging Disabled";
            case Enums.SystemLogType.MessagingAutoDisabled: 
                return "Messaging Auto-Disabled";
            case Enums.SystemLogType.MessagingEnabled: 
                return "Messaging Enabled";
            case Enums.SystemLogType.MessagingSettingsUpdated: 
                return "Messaging Settings Updated";
            case Enums.SystemLogType.MessagingLimitsUpdated: 
                return "Messaging Limits Updated";
            case Enums.SystemLogType.SMSNotificationsDisabled: 
                return "SMS Notifications have been disabled";
            case Enums.SystemLogType.SMTPNotificationsDisabled: 
                return "SMTP Notifications have been disabled";
            case Enums.SystemLogType.SMSNotificationsEnabled: 
                return "SMS Notifications have been enabled";
            case Enums.SystemLogType.SMTPNotificationsEnabled: 
                return "SMTP Notifications have been enabled";
            case Enums.SystemLogType.CallCounterThresholdExceeded: 
                return "Call Counter Threshold Exceeded";
            case Enums.SystemLogType.SMSNotificationsLimitReached: 
                return "SMS Notifications limit has been reached";
            case Enums.SystemLogType.EmailFromUnknownSourceReceived: 
                return "Email From Unknown Source Received";
            case Enums.SystemLogType.CustomerSettingsChanged: 
                return "Customer Settings Changed";
            case Enums.SystemLogType.ArchivedEvents: 
                return "Archived Events";
            case Enums.SystemLogType.MailboxPollFailure: 
                return "Failed to poll mailbox";
            case Enums.SystemLogType.DisturbanceProcessingFailed: 
                return "Disturbance Processing Failed";
            case Enums.SystemLogType.CsmVersionChanged: 
                return "CSM Version Changed";
            case Enums.SystemLogType.TmVersionChanged: 
                return "TM Version Changed";
            case Enums.SystemLogType.ScadaVersionChanged: 
                return "SCADA Version Changed";
            case Enums.SystemLogType.UserPasswordChanged: 
                return "User Password Changed";
            case Enums.SystemLogType.ForcedPasswordExpiry: 
                return "Forced Password Expiry";
            case Enums.SystemLogType.CredentialAdded: 
                return "Credential Added";
            case Enums.SystemLogType.CredentialUpdated: 
                return "Credential Updated";
            case Enums.SystemLogType.CredentialDeleted: 
                return "Credential Deleted";
            case Enums.SystemLogType.UserImpersonationStarted: 
                return "User Impersonation Started";
            case Enums.SystemLogType.UserVerificationCodeSent: 
                return "User Verification Code Sent";
            case Enums.SystemLogType.UserVerified: 
                return "User Verified";
            case Enums.SystemLogType.UserRejected: 
                return "User Rejected";
            case Enums.SystemLogType.CsmHostMachineChanged: 
                return "CSM Host Machine Changed";
            case Enums.SystemLogType.TmHostMachineChanged: 
                return "TM Host Machine Changed";
            case Enums.SystemLogType.ScadaHostMachineChanged: 
                return "SCADA Host Machine Changed";
            case Enums.SystemLogType.RTULocked: 
                return "RTU Locked";
            case Enums.SystemLogType.RTUUnlocked: 
                return "RTU Unlocked";
            case Enums.SystemLogType.UserAccessChanged: 
                return "User Access Changed";
            case Enums.SystemLogType.RtuFirmwareUploaded: 
                return "RTU Firmware Uploaded";
            case Enums.SystemLogType.RtuFirmwareDeleted: 
                return "RTU Firmware Deleted";
            case Enums.SystemLogType.NetworkAdded: 
                return "Network Added";
            case Enums.SystemLogType.NetworkSnapshotCreated:
                return "Network Snapshot Created";
            case Enums.SystemLogType.NetworkDeleted: 
                return "Network Deleted";
            case Enums.SystemLogType.NetworkSnapshotDeleted:
                return "Network Snapshot Deleted";
            case Enums.SystemLogType.SystemCertificateUploaded: 
                return "System Certificate Uploaded";
            case Enums.SystemLogType.SystemCertificateDeleted: 
                return "System Certificate Deleted";
            case Enums.SystemLogType.SystemCertificateCreated: 
                return "System Certificate Created";
            case Enums.SystemLogType.RtuCertificateCreated: 
                return "RTU Certificate Created";
            case Enums.SystemLogType.RtuCertificateDeleted: 
                return "RTU Certificate Deleted";
            case Enums.SystemLogType.UnknownRTUAddress: 
                return "Unknown RTU Address";
            case Enums.SystemLogType.RtuCertificateUploaded: 
                return "RTU Certificate Uploaded";
            case Enums.SystemLogType.MessageBrokerRestored: 
                return "Message Broker Restored";
            case Enums.SystemLogType.MessageBrokerFailed: 
                return "Message Broker Failed";
            case Enums.SystemLogType.RtuRestored: 
                return "RTU Restored";
            case Enums.SystemLogType.NoDataReceivedOnScadaChannel:
                return "No Data Received on SCADA Channel";
            case Enums.SystemLogType.UserMoved:
                return "User Moved";
            case Enums.SystemLogType.CustomerNoteAdded:
                return "Customer Note Added";
            case Enums.SystemLogType.CustomerNoteEdited:
                return "Customer Note Edited";
            case Enums.SystemLogType.CustomerNoteDeleted:
                return "Customer Note Deleted";
            case Enums.SystemLogType.PushNotificationsDisabled:
                return "Push Notifications have been disabled";
            case Enums.SystemLogType.PushNotificationsEnabled:
                return "Push Notifications have been enabled";
            case Enums.SystemLogType.SystemHealthAlarm:
                return "System Health Alarm";
            default:
                return "Undefined";
        }
    }

    public static getCompressionAlgorithmDescription(compressionAlgorithm: Enums.CompressionAlgorithm) {
        switch (compressionAlgorithm) {
            case Enums.CompressionAlgorithm.ConvergingWindow:
                return "Converging Window";
            case Enums.CompressionAlgorithm.Deadband:
                return "Deadband";
            default:
                return undefined;
        }
    }

    public static getRtuFileValueFormatDescription(rtuFileValueFormat: Enums.RtuFileValueFormat) {
        switch(rtuFileValueFormat) {
            case Enums.RtuFileValueFormat.None:
                return "None";
            case Enums.RtuFileValueFormat.Protocol:
                return "Protocol";
            case Enums.RtuFileValueFormat.Raw:
                return "Raw";
            case Enums.RtuFileValueFormat.Real:
                return "Real";
            default:
                return undefined;
        }
    }

    public static getRtuFileFormatDescription(rtuFileFormat: Enums.RtuFileFormat) {
        switch (rtuFileFormat) {
            case Enums.RtuFileFormat.None:
                return "None";
            case Enums.RtuFileFormat.EnvoyCsvDataLogs:
                return "CSV Datalogs (Envoy)";
            case Enums.RtuFileFormat.CsvEvents:
                return "CSV Datalogs (iHost)";
            case Enums.RtuFileFormat.CsvRtuTemplate:
                return "CSV RTU Template";
            case Enums.RtuFileFormat.SubnetCsvDataLogs:
                return "CSV Datalogs (eMS sub.net)";
            case Enums.RtuFileFormat.PqubeCsvDataLogs:
                return "CSV Datalogs (PQube Trends)";
            case Enums.RtuFileFormat.LandisGyrDataLogs:
                return "CSV Datalogs (Landis+Gyr)";
            case Enums.RtuFileFormat.SubnetHarmonicTrendEmail:
                return "Sub.net Harmonic Trend Email";
            case Enums.RtuFileFormat.SubnetFlickerTrendEmail:
                return "Sub.net Flicker Trend Email";
            case Enums.RtuFileFormat.LVCapJsonDataLogs:
                return "JSON Datalogs (LV-CAP)";
            case Enums.RtuFileFormat.CommissioningDatalog:
                return "Commissioning Datalog";
            case Enums.RtuFileFormat.PQDataArchivePQube3:
                return "PQ Data Archive (PQube3)";
            case Enums.RtuFileFormat.CsvPQDataLogsAeberle:
                return "CSV PQ Datalogs (a-eberle)";
            case Enums.RtuFileFormat.EventRecordingComtradeWaveform:
                return "Event Recording (COMTRADE Waveform)";
            case Enums.RtuFileFormat.CsvPQDataLogsPQube3:
                return "CSV PQ Datalogs (PQube3)";
            case Enums.RtuFileFormat.XmlPQEventPQube3:
                return "XML PQ Event (PQube3)";
            case Enums.RtuFileFormat.EventRecordingPQube3CsvWaveform:
                return "Event Recording (PQube3 CSV Waveform)";
            case Enums.RtuFileFormat.CsvPQEventsSiemens:
                return "CSV PQ Events (Siemens)";
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ200:
                return "PQDIF PQ Datalogs (Siemens Q200)";
            case Enums.RtuFileFormat.EventRecordingPQube3CsvRms:
                return "Event Recording (PQube3 CSV RMS)";
            case Enums.RtuFileFormat.EventRecordingComtradeRms:
                return "Event Recording (COMTRADE RMS)";
            case Enums.RtuFileFormat.EventRecordingArchiveComtrade:
                return "Event Recording Archive (COMTRADE)";
            case Enums.RtuFileFormat.PQDataArchiveSiemensQ200:
                return "PQ Data Archive (Siemens Q200)";
            case Enums.RtuFileFormat.CsvPQEventsAeberle:
                return "CSV PQ Events (a-eberle)";
            case Enums.RtuFileFormat.CsvRtuEvents:
                return "CSV RTU Events";
            case Enums.RtuFileFormat.NgesoPerfmonV1:
                return "NGESO Perfmon V1 (CSV)";
            case Enums.RtuFileFormat.RtuDataArchive:
                return "RTU Data Archive";
            case Enums.RtuFileFormat.NexusBinaryStatus:
                return "Nexus Binary Status";
            case Enums.RtuFileFormat.PQDataArchiveSiemensQ100:
                return "PQ Data Archive (Siemens Q100)";
            case Enums.RtuFileFormat.PqdifPQDataLogsSiemensQ100:
                return "PQDIF PQ Datalogs (Siemens Q100)";
            case Enums.RtuFileFormat.NgesoAvailabilityRedeclarationV1:
                return "NGESO Availability Redeclaration V1 (CSV)";
            case Enums.RtuFileFormat.NgesoBaselineDeclarationV1:
                return "NGESO Baseline Declaration V1 (CSV)";
            case Enums.RtuFileFormat.PQDataArchiveSiemensSiprotec7ke85:
                return "PQ Data Archive (Siemens SIPROTEC 7KE85)";
            case Enums.RtuFileFormat.SmartNavigator2CsvWaveform:
                return "Smart Navigator 2.0 Waveform Capture";
            case Enums.RtuFileFormat.Unrecognised:
                return "Unrecognised";
            default:
                return undefined;
        }
    }

    public static getChannelCategoryDescription(channelCategory: Enums.ChannelCategory) {
        switch (channelCategory) {
            case Enums.ChannelCategory.Other:
                return "Other";
            case Enums.ChannelCategory.VoltagePP:
                return "Voltages (PP)";
            case Enums.ChannelCategory.VoltagePh:
                return "Voltages (Ph)";
            case Enums.ChannelCategory.Current:
                return "Currents";
            case Enums.ChannelCategory.Frequency:
                return "Frequency";
            case Enums.ChannelCategory.Power:
                return "Powers";
            case Enums.ChannelCategory.Accelerometer:
                return "Accelerometer";
            default:
                return undefined;
        }
    }

    public static getChannelQuantityDescription(channelQuantity: Enums.ChannelQuantity) {
        switch (channelQuantity) {
            case Enums.ChannelQuantity.Other:
                return "Other";
            case Enums.ChannelQuantity.Voltage:
                return "Voltage";
            case Enums.ChannelQuantity.Current:
                return "Current";
            case Enums.ChannelQuantity.ActivePower:
                return "Active Power";
            case Enums.ChannelQuantity.ReactivePower:
                return "Reactive Power";
            case Enums.ChannelQuantity.ApparentPower:
                return "Apparent Power";
            case Enums.ChannelQuantity.PowerFactor:
                return "Power Factor";
            case Enums.ChannelQuantity.Frequency:
                return "Frequency";
            case Enums.ChannelQuantity.AccelerometerXYZ:
                return "Accelerometer XYZ";
            case Enums.ChannelQuantity.AccelerometerData:
                return "Accelerometer Data";
            default:
                return undefined;
        }
    }

    public static getChannelPhaseDescription(channelPhase: Enums.ChannelPhase) {
        switch (channelPhase) {
            case Enums.ChannelPhase.None:
                return "None";
            case Enums.ChannelPhase.L1N:
                return "L1N";
            case Enums.ChannelPhase.L2N:
                return "L2N";
            case Enums.ChannelPhase.L3N:
                return "L3N";
            case Enums.ChannelPhase.N:
                return "N";
            case Enums.ChannelPhase.L12:
                return "L12";
            case Enums.ChannelPhase.L23:
                return "L23";
            case Enums.ChannelPhase.L31:
                return "L31";
            default:
                return undefined;
        }
    }

    public static getEventRecordingTypeDescription(eventRecordingType: Enums.EventRecordingType) {
        switch (eventRecordingType) {
            case Enums.EventRecordingType.Unknown:
                return "Unknown";
            case Enums.EventRecordingType.Waveform:
                return "Waveform";
            case Enums.EventRecordingType.Rms:
                return "RMS";
            default:
                return undefined;
        }
    }

    public static getDayOfWeekDescription(dayOfWeek: Enums.DayOfWeek) {
        switch (dayOfWeek) {
            case Enums.DayOfWeek.Sunday:
                return "Sunday";
            case Enums.DayOfWeek.Monday:
                return "Monday";
            case Enums.DayOfWeek.Tuesday:
                return "Tuesday";
            case Enums.DayOfWeek.Wednesday:
                return "Wednesday";
            case Enums.DayOfWeek.Thursday:
                return "Thursday";
            case Enums.DayOfWeek.Friday:
                return "Friday";
            case Enums.DayOfWeek.Saturday:
                return "Saturday";
            default:
                return undefined;
        }
    }

    /**
     * Converts an Point Sub Type to human readable string.
     */
    public static getPointSubTypeDescription(pointSubType: Enums.PointSubType) {
        switch (pointSubType) {
            case Enums.PointSubType.BinaryInput:
                return "Binary Input";
            case Enums.PointSubType.BinaryOutput:
                return "Binary Output";
            case Enums.PointSubType.CounterInput:
                return "Counter Input";
            case Enums.PointSubType.AnalogueInput:
                return "Analogue Input";
            case Enums.PointSubType.AnalogueOutput:
                return "Analogue Output";
            case Enums.PointSubType.RtuBattery:
                return "RTU Battery";
            case Enums.PointSubType.RtuPower:
                return "RTU Power";
            case Enums.PointSubType.Rssi:
                return "Modem";
            case Enums.PointSubType.RtuEventSource:
                return "RTU Event Source";
            case Enums.PointSubType.GpsPosition:
                return "GPS Position";
            case Enums.PointSubType.RtuStatus:
                return "RTU Status";
            case Enums.PointSubType.RtuTemperature:
                return "Temperature";
            case Enums.PointSubType.FpiStatus:
                return "FPI Status";
            case Enums.PointSubType.Signature:
                return "Signature";
            case Enums.PointSubType.Ignition:
                return "Ignition";
            case Enums.PointSubType.StringInput:
                return "String Input";
            case Enums.PointSubType.DoubleBitInput:
                return "Double-bit Input";
            default:
                return undefined;
        }
    }

    public static getPointSubTypeAbbreviation(pointSubType: Enums.PointSubType) {
        switch (pointSubType) {
            case Enums.PointSubType.BinaryInput:
                return "BI";
            case Enums.PointSubType.AnalogueInput:
                return "AI";
            case Enums.PointSubType.CounterInput:
                return "CI";
            case Enums.PointSubType.StringInput:
                return "SI";
            case Enums.PointSubType.BinaryOutput:
                return "BO";
            case Enums.PointSubType.AnalogueOutput:
                return "AO";
            case Enums.PointSubType.DoubleBitInput:
                return "DI";
            default:
                return undefined;
        }
    }

    public static getVirtualPointTypeDescription(virtualPointType: Enums.VirtualPointType) {
        switch (virtualPointType) {
            case Enums.VirtualPointType.BinaryInput:
                return "Binary Input";
            case Enums.VirtualPointType.BinaryOutput:
                return "Binary Output";
            case Enums.VirtualPointType.CounterInput:
                return "Counter Input";
            case Enums.VirtualPointType.AnalogueInput:
                return "Analogue Input";
            case Enums.VirtualPointType.AnalogueOutput:
                return "Analogue Output";
            case Enums.VirtualPointType.StringInput:
                return "String Input";
            case Enums.VirtualPointType.DoubleBitInput:
                return "Double-bit Input";
            default:
                return undefined;
        }
    }

    public static getVirtualPointTypeAbbreviation(virtualPointType: Enums.VirtualPointType) {
        switch (virtualPointType) {
            case Enums.VirtualPointType.BinaryInput:
                return "BI";
            case Enums.VirtualPointType.AnalogueInput:
                return "AI";
            case Enums.VirtualPointType.CounterInput:
                return "CI";
            case Enums.VirtualPointType.StringInput:
                return "SI";
            case Enums.VirtualPointType.BinaryOutput:
                return "BO";
            case Enums.VirtualPointType.AnalogueOutput:
                return "AO";
            case Enums.VirtualPointType.DoubleBitInput:
                return "DI";
            default:
                return undefined;
        }
    }

    public static getReportRunStatusDescription(reportRunStatus: Enums.ReportRunStatus) {
        switch (reportRunStatus) {
            case Enums.ReportRunStatus.Pending:
                return "Pending";
            case Enums.ReportRunStatus.Running:
                return "Running";
            case Enums.ReportRunStatus.Success:
                return "Success";
            case Enums.ReportRunStatus.Failed:
                return "Failed";
            case Enums.ReportRunStatus.Deleted:
                return "Deleted";
            case Enums.ReportRunStatus.Processing:
                return "Processing";
            default:
                return undefined;
        }
    }

    public static getRtuFileSourceDescription(rtuFileSource: Enums.RtuFileSource) {
        switch (rtuFileSource) {
            case Enums.RtuFileSource.Unknown:
                return "Unknown";
            case Enums.RtuFileSource.Internal:
                return "Internal";
            case Enums.RtuFileSource.Device:
                return "Device";
            case Enums.RtuFileSource.User:
                return "User";
            case Enums.RtuFileSource.FileArchive:
                return "File Archive";
            default:
                return undefined;
        }
    }

    public static getNotificationMethodDescription(notificationMethod: Enums.NotificationMethod) {
        switch (notificationMethod) {
            case Enums.NotificationMethod.SMS:
                return "SMS";
            case Enums.NotificationMethod.SMTPEmail:
                return "Email";
            case Enums.NotificationMethod.Push:
                return "Push";
            default:
                return undefined;
        }
    }

    public static getMessageFormatDescription(messageFormat: Enums.MessageFormat) {
        switch (messageFormat) {
            case Enums.MessageFormat.PlainText:
                return "Plain Text";
            case Enums.MessageFormat.Html:
                return "HTML";
            default:
                return undefined;
        }
    }

    public static getNotificationTemplateTypeDescription(notificationTemplateType: Enums.NotificationTemplateType) {
        switch (notificationTemplateType) {
            case Enums.NotificationTemplateType.Simple:
                return "Simple";
            case Enums.NotificationTemplateType.Advanced:
                return "Advanced";
            default:
                return undefined;
        }
    }

    public static getMessageApiTypeDescription(messageApiType: Enums.MessageApiType) {
        switch (messageApiType) {
            case Enums.MessageApiType.SmsGateway:
                return "SMS Gateway";
            case Enums.MessageApiType.PushGateway:
                return "Push Gateway";
            default:
                return undefined;
        }
    }

    public static getEventRecordingFormatDescription(eventRecordingFormat: Enums.EventRecordingFormat) {
        switch (eventRecordingFormat) {
            case Enums.EventRecordingFormat.ComtradeCombined:
                return "Comtrade Combined";
            case Enums.EventRecordingFormat.Csv:
                return "CSV";
            default:
                return undefined;
        }
    }

    public static getEventLogStatusDescription(eventLogStatus: Enums.EventLogStatus) {
        switch (eventLogStatus) {
            case Enums.EventLogStatus.Acknowledged:
                return "Acknowledged";
            case Enums.EventLogStatus.Unacknowledged:
                return "Unacknowledged";
            default:
                return undefined;
        }
    }

    public static getMessageTypeDescription(messageType: Enums.MessageType) {
        switch (messageType) {
            case Enums.MessageType.OutgoingSMS:
                return "Outgoing SMS";
            case Enums.MessageType.IncomingSMS:
                return "Incoming SMS";
            case Enums.MessageType.OutgoingSMTP:
                return "Outgoing SMTP";
            case Enums.MessageType.IncomingSMTP:
                return "Incoming SMTP";
            case Enums.MessageType.OutgoingPush:
                return "Outgoing Push Message";
            case Enums.MessageType.PasswordResetEmail:
                return "Password Reset Email";
            case Enums.MessageType.MfaEmail:
                return "MFA Email";
            case Enums.MessageType.MfaSMS:
                return "MFA SMS";
            default:
                return undefined;
        }
    }


    public static getMessageStatusDescription(messageStatus: Enums.MessageStatus) {
        switch (messageStatus) {
            case Enums.MessageStatus.Pending:
                return "Pending";
            case Enums.MessageStatus.Sent:
                return "Sent";
            case Enums.MessageStatus.Failed:
                return "Failed";
            case Enums.MessageStatus.Received:
                return "Received";
            case Enums.MessageStatus.Expired:
                return "Expired";
            case Enums.MessageStatus.Retrying:
                return "Retrying";
            case Enums.MessageStatus.TimedOut:
                return "Timed Out";
            case Enums.MessageStatus.NotificationLimitReached:
                return "Notification Limit Reached";
            case Enums.MessageStatus.PendingReply:
                return "Pending Reply";
            case Enums.MessageStatus.Throttled:
                return "Throttled";
            case Enums.MessageStatus.Restricted:
                return "Restricted";
            case Enums.MessageStatus.Delivered:
                return "Delivered";
            default:
                return undefined;
        }
    }

    public static getEventStorageTypeDescription(eventStorageType: Enums.EventStorageType) {
        switch (eventStorageType) {
            case Enums.EventStorageType.Main:
                return "Main";
            case Enums.EventStorageType.Archive:
                return "Archive";
            default:
                return undefined;
        }
    }

    public static getCsmStatusDescription(status: Enums.CsmStatus) {
        switch (status) {
            case Enums.CsmStatus.Online:
                return "Online";
            case Enums.CsmStatus.Offline:
                return "Offline";
            case Enums.CsmStatus.Suspicious:
                return "Suspicious";
            case Enums.CsmStatus.Unknown:
                return "Unknown";
            default:
                return undefined;
        }
    }

    public static getCsmLineStatusDescription(status: Enums.CsmLineStatus) {
        switch (status) {
            case Enums.CsmLineStatus.Online:
                return "Online";
            case Enums.CsmLineStatus.Offline:
                return "Offline";
            case Enums.CsmLineStatus.Suspicious:
                return "Suspicious";
            case Enums.CsmLineStatus.Unknown:
                return "Unknown";
            case Enums.CsmLineStatus.Failed:
                return "Failed";
            default:
                return undefined;
        }
    }

    public static getTaskManagerStatusDescription(status: Enums.TaskManagerStatus) {
        switch (status) {
            case Enums.TaskManagerStatus.Online:
                return "Online";
            case Enums.TaskManagerStatus.Offline:
                return "Offline";
            case Enums.TaskManagerStatus.Suspicious:
                return "Suspicious";
            case Enums.TaskManagerStatus.Unknown:
                return "Unknown";
            default:
                return undefined;
        }
    }

    public static getTaskModuleStatusDescription(status: Enums.TaskModuleState) {
        switch (status) {
            case Enums.TaskModuleState.Stopped:
                return "Stopped";
            case Enums.TaskModuleState.Idle:
                return "Idle";
            case Enums.TaskModuleState.Running:
                return "Running";
            case Enums.TaskModuleState.Suspicious:
                return "Suspicious";
            case Enums.TaskModuleState.Disabled:
                return "Disabled";
            default:
                return undefined;
        }
    }

    public static getScadaStatusDescription(status: Enums.ScadaStatus) {
        switch (status) {
            case Enums.ScadaStatus.Online:
                return "Online";
            case Enums.ScadaStatus.Offline:
                return "Offline";
            case Enums.ScadaStatus.Suspicious:
                return "Suspicious";
            case Enums.ScadaStatus.Unknown:
                return "Unknown";
            case Enums.ScadaStatus.Failed:
                return "Failed";
            default:
                return undefined;
        }
    }

    public static getNetworkNodeTypeDescription(nodeType: Enums.NetworkNodeType) {
        switch (nodeType) {
            case Enums.NetworkNodeType.Rtu:
                return "RTU";
            case Enums.NetworkNodeType.Junction:
                return "Junction";
            case Enums.NetworkNodeType.LinkedNetwork:
                return "Linked Network";
            case Enums.NetworkNodeType.Point:
                return "Point";
            case Enums.NetworkNodeType.Bus:
                return "Bus";
            case Enums.NetworkNodeType.Infeeder:
                return "Infeeder";
            case Enums.NetworkNodeType.Generator:
                return "Generator";
            case Enums.NetworkNodeType.Load:
                return "Load";
            default:
                return "Unknown";
        }
    }

    public static getNetworkDisplayTypeDescription(nodeType: Enums.NetworkNodeDisplayType) {
        switch (nodeType) {
            case Enums.NetworkNodeDisplayType.Icon:
                return "Icon";
            case Enums.NetworkNodeDisplayType.Shape:
                return "Shape";
            default:
                return "Unknown";
        }
    }

    public static getNetworkEdgeTypeDescription(edgeType: Enums.NetworkEdgeType) {
        switch (edgeType) {
            case Enums.NetworkEdgeType.Line:
                return "Line";
            case Enums.NetworkEdgeType.Switch:
                return "Switch";
            case Enums.NetworkEdgeType.Transformer:
                return "Transformer";
            case Enums.NetworkEdgeType.Cable:
                return "Cable";
            default:
                return "Unknown";
        }
    }

    public static getNetworkGroupTypeDescription(groupType: Enums.NetworkGroupType) {
        switch (groupType) {
            case Enums.NetworkGroupType.Group:
                return "Group";
            case Enums.NetworkGroupType.Feeder:
                return "Feeder";
            default:
                return "Unknown";
        }
    }

    public static getOtpTypeDescription(otpType: Enums.OtpType): string | undefined {
        switch (otpType) {
            case Enums.OtpType.Totp:
                return "Authenticator App";
            case Enums.OtpType.Email:
                return "Email Authentication";
            case Enums.OtpType.RecoveryCode:
                return "Recovery Code";
            case Enums.OtpType.SMS:
                return "SMS Authentication";
            default:
                return undefined;
        }
    }

    public static getNetworkFaultLocationStatusDescription(networkFaultLocationStatus: Enums.NetworkFaultLocationStatus) {
        switch (networkFaultLocationStatus) {
            case Enums.NetworkFaultLocationStatus.Calculated:
                return "Calculated";
            case Enums.NetworkFaultLocationStatus.NoResult:
                return "No Result";
            case Enums.NetworkFaultLocationStatus.Error:
                return "Error";
            default:
                return undefined;
        }
    }

    public static getNetworkFaultCategoryDescription(networkFaultCategory: Enums.NetworkFaultCategory) {
        switch (networkFaultCategory) {
            case Enums.NetworkFaultCategory.Indeterminate:
                return "Indeterminate";
            case Enums.NetworkFaultCategory.PostFault:
                return "Post-Fault";
            case Enums.NetworkFaultCategory.PreFault:
                return "Pre-Fault";
            default:
                return undefined;
        }
    }

    public static getSystemServiceTypeDescription(serviceType: Enums.SystemServiceType) {
        switch (serviceType) {
            case Enums.SystemServiceType.Csm:
                return "CSM";
            case Enums.SystemServiceType.TaskManager:
                return "Task Manager";
            case Enums.SystemServiceType.ScadaInterface:
                return "SCADA Interface";
            default:
                return "Unknown";
        }
    }

    public static getLogEventLevelDescription(logLevel: Enums.LogEventLevel) {
        switch (logLevel) {
            case Enums.LogEventLevel.Verbose:
                return "Verbose";
            case Enums.LogEventLevel.Debug:
                return "Debug";
            case Enums.LogEventLevel.Info:
                return "Info";
            case Enums.LogEventLevel.Warning:
                return "Warning";
            case Enums.LogEventLevel.Error:
                return "Error";
            case Enums.LogEventLevel.Fatal:
                return "Fatal";
            default:
                return "Unknown";
        }
    }

    public static getRtuBulkImportFormatDescription(importFormat: Enums.RtuBulkImportFormat) {
        switch (importFormat) {
            case Enums.RtuBulkImportFormat.Csv:
                return "CSV";
            case Enums.RtuBulkImportFormat.VisnetEnrollmentPackage:
                return "VisNet Enrollment Package";
            default:
                return "Unknown";
        }
    }

    public static getEventRecordingCalculationModeDescription(calculationMode: Enums.EventRecordingCalculationMode) {
        switch (calculationMode) {
            case Enums.EventRecordingCalculationMode.FaultImpedance:
                return "Fault Impedance (Reactance Method)";
            case Enums.EventRecordingCalculationMode.EnergyInPreFaultPeck:
                return "Energy in Pre-Fault Peck (I2t)";
            default:
                return "Unknown";
        }
    }

    public static getFaultPhaseTypeDescription(faultPhaseType: Enums.FaultPhaseType) {
        switch (faultPhaseType) {
            case Enums.FaultPhaseType.Unknown:
                return "Unknown";
            case Enums.FaultPhaseType.Earth:
                return "Earth";
            case Enums.FaultPhaseType.PhaseToPhase:
                return "Phase-to-Phase";
            default:
                return "Unknown";
        }
    }
}

export class EventTypeHelper {
    public static readonly AnalogueInputThresholdCrossedEvents: Enums.EventType[]  = [
        Enums.EventType.AnalogueThresholdCrossed,
        Enums.EventType.AnalogueThresholdCrossedRising,
        Enums.EventType.AnalogueThresholdCrossedFalling,
        Enums.EventType.LowLowThresholdCrossedRising,
        Enums.EventType.LowThresholdCrossedRising,
        Enums.EventType.HighThresholdCrossedRising,
        Enums.EventType.HighHighThresholdCrossedRising,
        Enums.EventType.LowLowThresholdCrossedFalling,
        Enums.EventType.LowThresholdCrossedFalling,
        Enums.EventType.HighThresholdCrossedFalling,
        Enums.EventType.HighHighThresholdCrossedFalling
    ];

    public static isPQEventType(eventType: Enums.EventType): boolean {
        return eventType === Enums.EventType.PQEvent ||
            eventType === Enums.EventType.PQPendingEvent ||
            eventType === Enums.EventType.PQIncompleteEvent;
    }
}

export class NotificationsHelper {
    public static getEventDescription(point: Inputs.Input, eventType: Enums.EventType): string {
        if (point) {
            const bi = point as Inputs.BinaryInput;
            const di = point as Inputs.DoubleBitInput;

            switch (eventType) {
                case Enums.EventType.BinaryInputClosed:
                    return bi.NormallyOpen ? bi.AlarmText : bi.HealthyText;
                case Enums.EventType.BinaryInputOpen:
                    return !bi.NormallyOpen ? bi.AlarmText: bi.HealthyText;
                case Enums.EventType.BinaryInputStatusClosed:
                    return "Status " + (bi.NormallyOpen ? bi.AlarmText : bi.HealthyText);
                case Enums.EventType.BinaryInputStatusOpened:
                    return "Status " + (!bi.NormallyOpen ? bi.AlarmText : bi.HealthyText);
                case Enums.EventType.DoubleBitInputIntermediate:
                    return di.IntermediateText;
                case Enums.EventType.DoubleBitInputClosed:
                    return di.NormallyOpen ? di.AlarmText : di.HealthyText;
                case Enums.EventType.DoubleBitInputOpen:
                    return !di.NormallyOpen ? di.AlarmText : di.HealthyText;
                case Enums.EventType.DoubleBitInputIndeterminate:
                    return di.IndeterminateText;
                default:
                    return EnumHelper.getEventLogTypeDescription(eventType);
            };
        }
        return EnumHelper.getEventLogTypeDescription(eventType);
    }
}

export class MapHelper {

    /**
     * Converts a Map Source Protocol to human readable string.
     */
    public static getMapSourceProtocolString(mapSourceProtocol: Enums.MapSourceProtocol) {
        switch (mapSourceProtocol) {
            case Enums.MapSourceProtocol.OpenStreetMaps:
                return "Open Street Maps";
            case Enums.MapSourceProtocol.BingMaps:
                return "Bing Maps";
            case Enums.MapSourceProtocol.Wmts:
                return "WMTS";
            case Enums.MapSourceProtocol.Wms:
                return "WMS";
            default:
                return "Unknown";
        }
    }

    /**
     * Converts a Map Source Protocol to human readable string.
     */
    public static getMapSourceTypeString(mapSourceProtocol: Enums.MapSourceType) {
        switch (mapSourceProtocol) {
            case Enums.MapSourceType.Background:
                return "Background";
            case Enums.MapSourceType.Feature:
                return "Feature";
            default:
                return "Unknown";
        }
    }

    /**
     * Converts a Map Source Access Type to human readable string.
     */
    public static getMapSourceAccessType(mapSourceAccessType: Enums.MapSourceAccessType) {
        switch (mapSourceAccessType) {
            case Enums.MapSourceAccessType.Direct:
                return "Direct";
            case Enums.MapSourceAccessType.Proxy:
                return "Proxy";
            default:
                return "Unknown";
        }
    }
}